.notfound {
  padding: 0 1.17rem;
}

.notfound .not_img {
  width: 100%;
  height: 4.47rem;
  margin-top: 0.9rem;
}

.notfound .not_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.9rem;
}

.notfound .not_con .con_text {
  font-size: 0.36rem;
  color: #252931;
  line-height: 0.5rem;
  margin-top: 0.72rem;
}

.notfound .not_con .con_home {
  margin-top: 0.36rem;
  width: 2.64rem;
  height: 0.73rem;
  background: #0064EB;
  font-size: 0.24rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 750px) and (max-width: 960px) {
  .notfound {
    padding: 0 2.27rem;
  }

  .notfound .not_img {
    width: 100%;
    height: 4.47rem;
    margin-top: 0.9rem;
  }

  .notfound .not_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.9rem;
  }

  .notfound .not_con .con_text {
    font-size: 0.36rem;
    color: #252931;
    line-height: 0.5rem;
    margin-top: 0.72rem;
  }

  .notfound .not_con .con_home {
    margin-top: 0.36rem;
    width: 2.64rem;
    height: 0.73rem;
    background: #0064EB;
    font-size: 0.24rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 961px) {
  .notfound {
    padding: 0.78rem 0 0.53rem 0;
    width: 12rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }

  .notfound .not_img {
    width: 5.16rem;
    height: 4.47rem;
    margin-top: 0;
    margin-left: 0.67rem;
  }

  .notfound .not_con {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 0;
  }

  .notfound .not_con .con_text {
    font-size: 0.3rem;
    color: #252931;
    line-height: 0.42rem;
    margin-top: 0;
  }

  .notfound .not_con .con_home {
    margin-top: 0.27rem;
    width: 1.6rem;
    height: 0.49rem;
    background: #0064EB;
    font-size: 0.16rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}