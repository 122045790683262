.con {
  height: 3.6rem;
  background-image: url(~@/assets/images/product/djCardboard/contact_bottom_bg.jpg);
  background-size: 100% 100%;
  position: relative;
}

.con .warp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.con .warp .titl {
  font-size: 0.28rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.37rem;
  margin-bottom: 0.24rem;
}

.con .warp .btn-warp {
  text-align: center;
}

.con .warp .btn-warp .btn {
  cursor: pointer;
  display: inline-block;
  width: 2.24rem;
  height: 0.7rem;
  background: #0064eb;
  border-radius: 0.04rem;
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.26rem;
  text-align: center;
  line-height: 0.7rem;
}