.swiper-item-box {
  height: 4.46rem;
  box-sizing: border-box;
  background: url("../../../../assets/images/news/news_bg.jpg");
  background-size: 100% 100%;
  padding: 0.37rem 0.24rem 0.24rem 0.24rem;
  position: relative;
}

.swiper-item-time {
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.2rem;
}

.swiper-item-title {
  font-size: 0.2rem;
  color: #333333;
  line-height: 0.3rem;
  margin-top: 0.12rem;
  margin-bottom: 0.32rem;
}

.swiper-item-content {
  font-size: 0.16rem;
  color: #999999;
  line-height: 0.25rem;
  height: 2.04rem;
}

.swiper-item-img {
  vertical-align: bottom;
  width: 100%;
  height: 4.46rem;
}

.swiper-item-btn {
  width: calc(100% - 0.48rem);
  position: absolute;
  bottom: 0.24rem;
}