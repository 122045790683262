.all-btn {
  width: 1.76rem;
  height: 0.51rem;
  line-height: 0.51rem;
  border: 0.01rem solid #0064EB;
  text-align: center;
  display: inline-block;
  color: #0064EB;
  cursor: pointer;
  font-size: 0.14rem;
}

.all-btn-icon {
  margin-left: 0.06rem;
}

.all-btn-icon>img {
  margin-bottom: 0.01rem;
}

.all-btn-icon .normal {
  display: inline;
}

.all-btn-icon .hover {
  display: none;
}

.all-btn:hover {
  color: #FFFFFF;
  background: #0064EB;
}

.all-btn:hover .all-btn-icon .normal {
  display: none;
}

.all-btn:hover .all-btn-icon .hover {
  display: inline;
}