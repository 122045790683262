.header-menu {
  padding: 0.35rem 0;
}

.header-menu .header-menu-icon {
  width: 0.48rem;
  height: 0.48rem;
}

.header-menu .header-menu-list {
  display: none;
  position: absolute;
  bottom: 0.01rem;
  left: 0;
  transform: translateY(100%);
  padding: 0.44rem 0.6rem 0.6rem 0.6rem;
  background-color: #FFFFFF;
  border-top: 0.01rem solid #F5F5F5;
  width: 100%;
}

.header-menu .header-menu-list__item {
  display: block;
  text-align: left;
  height: 0.66rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.66rem;
  border-bottom: 0.01rem solid #F5F5F5;
}

.header-menu .header-menu-list.isOpen {
  display: block;
}