.djchannel>div:nth-of-type(2) {
  text-align: center;
}

.djchannel>div:nth-of-type(2) .con {
  position: relative;
  text-align: center;
  padding: 0.4rem 0 0 0;
  display: inline-block;
  max-width: 12rem;
  width: 12rem;
  text-align: left;
}

.djchannel>div:nth-of-type(2) .con .lef {
  display: inline-block;
  margin-right: 0.54rem;
}

.djchannel>div:nth-of-type(2) .con .lef .active .war {
  border-left-color: #0064EB !important;
  background: #f5f5f5;
}

.djchannel>div:nth-of-type(2) .con .lef .active .war .tex {
  color: #0064eb !important;
}

.djchannel>div:nth-of-type(2) .con .lef .itm {
  padding: 0.29rem 0;
  text-align: left;
}

.djchannel>div:nth-of-type(2) .con .lef .itm .war {
  width: 2.8rem;
  height: 0.67rem;
  padding-left: 0.32rem;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 0.04rem solid transparent;
}

.djchannel>div:nth-of-type(2) .con .lef .itm .war .ico {
  display: inline-block;
  width: 0.22rem;
  height: 0.21rem;
  vertical-align: middle;
  margin-right: 0.13rem;
  background-size: 100% 100%;
}

.djchannel>div:nth-of-type(2) .con .lef .itm .war .tex {
  display: inline-block;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.24rem;
  vertical-align: middle;
}

.djchannel>div:nth-of-type(2) .con .lef .itm .war::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.djchannel>div:nth-of-type(2) .con .lef .itm:hover .war .tex {
  color: #0064eb;
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_nomal_zoushi.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_nomal_qianli.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_nomal_tongdian.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_nomal_jingzheng.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(1):hover .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_sel_zoushi.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(2):hover .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_hover_shichang.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(3):hover .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_sel_tongdian.svg");
}

.djchannel>div:nth-of-type(2) .con .lef>div:nth-of-type(4):hover .ico {
  background-image: url("~@/assets/images/contact/contact_iocn_sel_jingzheng.svg");
}

.djchannel>div:nth-of-type(2) .con .rig {
  display: inline-block;
  vertical-align: top;
}

.djchannel>div:nth-of-type(2) .con .rig .inf {
  text-align: left;
  height: 2.92rem;
  background: rgba(255,255,255,0.95);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.1);
  border: 0.01rem solid #e7eaf3;
  padding: 0.3rem 0.32rem 0 0.32rem;
  box-sizing: border-box;
}

.djchannel>div:nth-of-type(2) .con .rig .inf .titl {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #0064eb;
  line-height: 0.26rem;
  margin-bottom: 0.12rem;
}

.djchannel>div:nth-of-type(2) .con .rig .inf .dec {
  width: 6.61rem;
  font-size: 0.14rem;
  color: #666666;
  line-height: 0.24rem;
}

.djchannel>div:nth-of-type(2) .con .rig .inf .dec>p+p {
  margin-top: 0.16rem;
}

.djchannel>div:nth-of-type(2) .con .bg {
  position: absolute;
  top: 0.52rem;
  left: 4.6rem;
  z-index: -1;
  width: 11rem;
  height: 4.48rem;
  background-size: 100% 100%;
}

.djchannel>div:nth-of-type(3) .con .des {
  padding: 0.24rem 0 0.5rem 0;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.22rem;
  text-align: center;
}

.djchannel>div:nth-of-type(3) .con .top {
  text-align: center;
  margin-bottom: 0.24rem;
}

.djchannel>div:nth-of-type(3) .con .top .itm {
  vertical-align: top;
  margin: 0 0.12rem;
  display: inline-block;
  box-sizing: border-box;
  width: 2.82rem;
  height: 3.66rem;
  background: #ffffff;
  border: 0.01rem solid #eeeeee;
  text-align: center;
  padding: 0.52rem 0.24rem 0 0.24rem;
}

.djchannel>div:nth-of-type(3) .con .top .itm .ico {
  display: inline-block;
  height: 0.88rem;
  width: 0.88rem;
  margin-bottom: 0.34rem;
}

.djchannel>div:nth-of-type(3) .con .top .itm .titl {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.26rem;
}

.djchannel>div:nth-of-type(3) .con .top .itm .des {
  text-align: left;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.22rem;
}

.djchannel>div:nth-of-type(3) .con .top .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djchannel>div:nth-of-type(3) .con .top>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/contact/contact_advante_iocn_cd.svg");
}

.djchannel>div:nth-of-type(3) .con .top>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/contact/contact_advante_iocn_xl.svg");
}

.djchannel>div:nth-of-type(3) .con .top>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/contact/contact_advante_iocn_ry.svg");
}

.djchannel>div:nth-of-type(3) .con .top>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/contact/contact_advante_iocn_ys.svg");
}

.djchannel>div:nth-of-type(3) .con .bot {
  text-align: center;
  margin-bottom: 0.8rem;
}

.djchannel>div:nth-of-type(3) .con .bot .itm {
  display: inline-block;
  width: 5.88rem;
  margin: 0 0.12rem;
  vertical-align: top;
  border: 0.01rem solid #F5F5F5;
  border-top: none;
  box-sizing: border-box;
}

.djchannel>div:nth-of-type(3) .con .bot .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djchannel>div:nth-of-type(3) .con .bot .itm .img {
  height: 2.23rem;
  background-size: 100% 100%;
}

.djchannel>div:nth-of-type(3) .con .bot .itm .info {
  height: 1.43rem;
  box-sizing: border-box;
  padding: 0.24rem 0.25rem 0 0.25rem;
}

.djchannel>div:nth-of-type(3) .con .bot .itm .info .titl {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.26rem;
  margin-bottom: 0.16rem;
}

.djchannel>div:nth-of-type(3) .con .bot .itm .info .des {
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.22rem;
  padding: 0;
}

.djchannel>div:nth-of-type(3) .con .bot>div:nth-of-type(1) .img {
  background-image: url("~@/assets/images/contact/contact_advante_bg_product.jpg");
}

.djchannel>div:nth-of-type(3) .con .bot>div:nth-of-type(2) .img {
  background-image: url("~@/assets/images/contact/contact_advante_bg_high.jpg");
}

.djchannel>div:nth-of-type(4) {
  background: #f4faff;
}

.djchannel>div:nth-of-type(4) .con {
  text-align: center;
  padding: 0.51rem 0 0.8rem 0;
}

.djchannel>div:nth-of-type(4) .con .itm {
  margin: 0 0.12rem;
  display: inline-block;
  box-sizing: border-box;
  width: 2.82rem;
  height: 3.24rem;
  background: #0064eb;
  border: 0.01rem solid #eeeeee;
  text-align: center;
  padding: 0.65rem 0.24rem 0 0.24rem;
  vertical-align: top;
}

.djchannel>div:nth-of-type(4) .con .itm .ico {
  display: inline-block;
  height: 0.45rem;
  width: 0.45rem;
  margin-bottom: 0.43rem;
  background-size: 100% 100%;
}

.djchannel>div:nth-of-type(4) .con .itm .titl {
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.24rem;
  margin-bottom: 0.19rem;
}

.djchannel>div:nth-of-type(4) .con .itm .des {
  text-align: left;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.6);
  line-height: 0.19rem;
}

.djchannel>div:nth-of-type(4) .con .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
  background-color: #fff;
  transform: translateY(-0.1rem);
  transition: transform 300ms;
}

.djchannel>div:nth-of-type(4) .con .itm:hover .titl {
  color: #333333;
}

.djchannel>div:nth-of-type(4) .con .itm:hover .des {
  color: #999999;
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_nomal_zy.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_nomal_zc.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_nomal_fw.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_nomal_zd.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(1):hover .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_hover_zy.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(2):hover .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_hover_zc.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(3):hover .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_hover_fw.svg");
}

.djchannel>div:nth-of-type(4) .con>div:nth-of-type(4):hover .ico {
  background-image: url("~@/assets/images/contact/contact_support_iocn_hover_zd.svg");
}

.djchannel>div:nth-of-type(5) .con {
  text-align: center;
  padding-top: 0.5rem;
}

.djchannel>div:nth-of-type(5) .con .itm {
  margin: 0 0.12rem;
  display: inline-block;
  box-sizing: border-box;
  width: 3.84rem;
  height: 2.1rem;
  text-align: left;
  vertical-align: top;
  padding: 0.32rem 0.24rem 0 0.24rem;
}

.djchannel>div:nth-of-type(5) .con .itm:hover {
  background: #ffffff;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djchannel>div:nth-of-type(5) .con .itm .titl {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.26rem;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.djchannel>div:nth-of-type(5) .con .itm .titl::after {
  margin-top: 0.1rem;
  display: block;
  content: "";
  width: 0.34rem;
  height: 0.04rem;
  background: #0064eb;
}

.djchannel>div:nth-of-type(5) .con .itm .des {
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.22rem;
}

.djchannel>div:nth-of-type(6) .con {
  text-align: center;
  padding: 0.5rem 0 0.8rem 0;
}

.djchannel>div:nth-of-type(6) .con>div:nth-of-type(1) {
  background-image: url("~@/assets/images/contact/contact_bg_join.png");
  background-size: 100% 100%;
  vertical-align: top;
}

.djchannel>div:nth-of-type(6) .con>div:nth-of-type(2) {
  background-image: url("~@/assets/images/contact/contact_bg_cooperation.png");
  background-size: 100% 100%;
  vertical-align: top;
}

.djchannel>div:nth-of-type(6) .con>div:nth-of-type(3) {
  background-image: url("~@/assets/images/contact/contact_bg_sign.png");
  background-size: 100% 100%;
  vertical-align: top;
}

.djchannel>div:nth-of-type(6) .con>div:nth-of-type(4) {
  background-image: url("~@/assets/images/contact/contact_bg_train.png");
  background-size: 100% 100%;
  vertical-align: top;
}

.djchannel>div:nth-of-type(6) .con>div:nth-of-type(5) {
  background-image: url("~@/assets/images/contact/contact_bg_profit.png");
  background-size: 100% 100%;
  vertical-align: top;
}

.djchannel>div:nth-of-type(6) .con .divide {
  width: 0.61rem;
  display: inline-block;
  height: 2.1rem;
  position: relative;
}

.djchannel>div:nth-of-type(6) .con .divide>img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.djchannel>div:nth-of-type(6) .con .itm {
  display: inline-block;
  box-sizing: border-box;
  width: 1.92rem;
  height: 2.1rem;
  text-align: left;
  border: 0.01rem solid #eeeeee;
  padding: 0.16rem 0 0 0.13rem;
}

.djchannel>div:nth-of-type(6) .con .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djchannel>div:nth-of-type(6) .con .itm .titl {
  font-size: 0.38rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 0.5rem;
}

.djchannel>div:nth-of-type(6) .con .itm .des {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.21rem;
}

.djchannel>div:nth-of-type(7) .con {
  height: 3.6rem;
  background-color: #000000;
  position: relative;
}

.djchannel>div:nth-of-type(7) .con .warp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.djchannel>div:nth-of-type(7) .con .warp .titl {
  font-size: 0.28rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.37rem;
  margin-bottom: 0.24rem;
}

.djchannel>div:nth-of-type(7) .con .warp .btn-warp {
  text-align: center;
}

.djchannel>div:nth-of-type(7) .con .warp .btn-warp .btn {
  display: inline-block;
  width: 2.24rem;
  height: 0.7rem;
  background: #0064eb;
  border-radius: 0.04rem;
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.26rem;
  text-align: center;
  line-height: 0.7rem;
}