.item {
  width: 100%;
  height: 2.58rem;
  box-sizing: border-box;
  position: relative;
}

.item-img {
  width: 1.6rem;
  height: 100%;
  position: absolute;
}

.item-img>img {
  width: 0.88rem;
  height: 0.88rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.item-text {
  padding-left: 1.6rem;
  padding-right: 0.46rem;
  display: inline-block;
  margin-top: 0.48rem;
}

.item-text-title {
  font-size: 0.2rem;
  color: #333333;
  line-height: 0.26rem;
  margin-bottom: 0.13rem;
}

.item-text-content {
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.3rem;
}

.item:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}