.djCardboard>div:nth-of-type(2) {
  text-align: center;
}

.djCardboard>div:nth-of-type(2)>div:first-child {
  padding-top: 0;
}

.djCardboard>div:nth-of-type(2) .con {
  padding: 0.6rem 0 0.8rem 0;
  display: inline-block;
  max-width: 12rem;
  width: 12rem;
  text-align: left;
}

.djCardboard>div:nth-of-type(2) .con>div>div:nth-of-type(1)>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djCardboard/painpoint_cost.svg");
}

.djCardboard>div:nth-of-type(2) .con>div>div:nth-of-type(1)>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djCardboard/painpoint_quantity.svg");
}

.djCardboard>div:nth-of-type(2) .con>div>div:nth-of-type(1)>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djCardboard/painpoint_transport.svg");
}

.djCardboard>div:nth-of-type(2) .con>div>div:nth-of-type(2)>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djCardboard/painpoint_service.svg");
}

.djCardboard>div:nth-of-type(2) .con>div>div:nth-of-type(2)>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djCardboard/painpoint_pay.svg");
}

.djCardboard>div:nth-of-type(2) .con .itm {
  width: 100%;
  height: 2.02rem;
  border: 0.01rem solid #eeeeee;
  padding: 0.36rem 0 0.36rem 0.36rem;
  box-sizing: border-box;
  text-align: left;
  padding: 0.36rem 0 0.36rem 0.36rem;
  display: inline-block;
  background: #ffffff;
  vertical-align: top;
}

.djCardboard>div:nth-of-type(2) .con .itm .ico {
  height: 0.4rem;
  width: 0.4rem;
  margin-bottom: 0.26rem;
}

.djCardboard>div:nth-of-type(2) .con .itm .titl {
  font-size: 0.24rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.31rem;
  margin-bottom: 0.12rem;
}

.djCardboard>div:nth-of-type(2) .con .itm .des {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.21rem;
}

.djCardboard>div:nth-of-type(2) .con .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djCardboard>div:nth-of-type(3) {
  text-align: center;
}

.djCardboard>div:nth-of-type(3) .item-group {
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  max-width: 12rem;
  width: 100%;
  display: inline-block;
}

.djCardboard>div:nth-of-type(3) .item-group>div>div {
  transition: width 0.5s;
}

.djCardboard>div:nth-of-type(3) .item-group .itm:hover {
  background-color: #0064EB;
}

.djCardboard>div:nth-of-type(3) .item-group .itm:hover .des,
.djCardboard>div:nth-of-type(3) .item-group .itm:hover .titl {
  color: #ffffff;
}

.djCardboard>div:nth-of-type(3) .item-group .itm:hover .des {
  max-width: 4.37rem;
  opacity: 0.8;
}

.djCardboard>div:nth-of-type(3) .item-group .itm:hover .tip {
  background-color: #ffffff;
}

.djCardboard>div:nth-of-type(3) .item-group>div:nth-of-type(1)>div:nth-of-type(1) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_marketing.png");
}

.djCardboard>div:nth-of-type(3) .item-group>div:nth-of-type(1)>div:nth-of-type(2) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_paper.png");
}

.djCardboard>div:nth-of-type(3) .item-group>div:nth-of-type(2)>div:nth-of-type(1) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_delivery.png");
}

.djCardboard>div:nth-of-type(3) .item-group>div:nth-of-type(2)>div:nth-of-type(2) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_technology.png");
}

.djCardboard>div:nth-of-type(3) .item-group>div:nth-of-type(2)>div:nth-of-type(3) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_manage.png");
}

.djCardboard>div:nth-of-type(3) .itm {
  width: 100%;
  position: relative;
  height: 4rem;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  background: #ffffff;
  border: 0.01rem solid #f5f5f5;
  text-align: left;
  padding: 0.6rem 0 0.6rem 0.6rem;
  background-repeat: no-repeat;
  background-size: 3.5rem auto;
  background-position: right bottom;
}

.djCardboard>div:nth-of-type(3) .itm .titl {
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.29rem;
  margin-bottom: 0.36rem;
}

.djCardboard>div:nth-of-type(3) .itm .des {
  max-width: 2.8rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.24rem;
}

.djCardboard>div:nth-of-type(3) .itm .titl {
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.29rem;
  margin-bottom: 0.36rem;
}

.djCardboard>div:nth-of-type(3) .itm .des {
  height: 2rem;
  overflow: hidden;
  max-width: 2.8rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.24rem;
}

.djCardboard>div:nth-of-type(3) .tip {
  width: 0.56rem;
  height: 0.08rem;
  background: #0064EB;
  position: absolute;
  bottom: 0.6rem;
  left: 0.6rem;
}

.djCardboard>div:nth-of-type(4) .con {
  padding: 0.5rem 0 0.8rem 0;
}

.djCardboard>div:nth-of-type(4) .con .itm {
  text-align: center;
}

.djCardboard>div:nth-of-type(4) .con .itm .lef {
  margin: 0 0.4rem;
  display: inline-block;
  text-align: left;
}

.djCardboard>div:nth-of-type(4) .con .itm .lef .titl {
  font-size: 0.26rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.35rem;
  margin-bottom: 0.24rem;
}

.djCardboard>div:nth-of-type(4) .con .itm .lef .des {
  max-width: 4.9rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.3rem;
  vertical-align: middle;
}

.djCardboard>div:nth-of-type(4) .con .itm .rig {
  margin: 0.4rem;
  display: inline-block;
  height: 3.92rem;
  width: 6.3rem;
  vertical-align: middle;
}

.djCardboard>div:nth-of-type(4) .con .itm::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.djCardboard>div:nth-of-type(4) .con>div:nth-of-type(1) .rig {
  background-image: url("~@/assets/images/product/djCardboard/feat_img_1.svg");
}

.djCardboard>div:nth-of-type(4) .con>div:nth-of-type(2) .rig {
  background-image: url("~@/assets/images/product/djCardboard/feat_img_2.svg");
}

.djCardboard>div:nth-of-type(4) .con>div:nth-of-type(3) .rig {
  background-image: url("~@/assets/images/product/djCardboard/feat_img_3.svg");
}

.djCardboard>div:nth-of-type(5) {
  background: #0064eb;
}

.djCardboard>div:nth-of-type(5) .lst {
  text-align: center;
  padding: 1rem 0 1.45rem 0;
}

.djCardboard>div:nth-of-type(5) .lst .itm {
  display: inline-block;
  margin: 0 0.76rem;
}

.djCardboard>div:nth-of-type(5) .lst .itm .ico {
  display: inline-block;
  height: 1.45rem;
  width: 1.45rem;
  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
}

.djCardboard>div:nth-of-type(5) .lst .itm .inf {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.4rem;
  text-align: left;
}

.djCardboard>div:nth-of-type(5) .lst .itm .inf .num {
  font-size: 0.26rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  line-height: 0.35rem;
  margin-bottom: 0.1rem;
}

.djCardboard>div:nth-of-type(5) .lst .itm .inf .des {
  margin-bottom: 0;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  line-height: 0.3rem;
  opacity: 0.7;
}

.djCardboard>div:nth-of-type(5) .lst>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djCardboard/super_1.svg");
}

.djCardboard>div:nth-of-type(5) .lst>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djCardboard/super_2.svg");
}

.djCardboard>div:nth-of-type(5) .lst>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djCardboard/super_3.svg");
}