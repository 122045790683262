.detail-btn {
  width: 100%;
  border: 0.01rem solid #0064EB;
  background: #0064EB;
  cursor: pointer;
  overflow: hidden;
}

.detail-btn-txt {
  float: left;
  height: 0.57rem;
  line-height: 0.57rem;
  text-align: center;
  display: inline-block;
  font-size: 0.18rem;
  color: #FFFFFF;
  width: calc(100% - 0.67rem);
}

.detail-btn-icon {
  float: right;
  display: inline-block;
  width: 0.67rem;
}

.detail-btn-icon>img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

.detail-btn:hover {
  background: #0057CD;
}