.slide-left-enter-active,
.slide-left-appear-active {
  position: relative;
  right: -2rem;
  opacity: 0;
}

.slide-left-enter-done,
.slide-left-appear-done {
  opacity: 1;
  position: relative;
  right: 0;
  transition-property: right, opacity;
  transition-duration: 1000ms;
}

.slide-left-exit-active {
  opacity: 1;
  position: relative;
  right: 0;
}

.slide-left-exit-done {
  position: relative;
  right: -2rem;
  opacity: 0;
  transition-property: right, opacity;
  transition-duration: 1000ms;
}