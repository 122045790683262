.dj-display {
  height: 3.6rem;
  background: rgba(0,0,0,0.5);
  background-size: auto 100%;
  position: relative;
  background-origin: content-box;
  background-position-x: center;
}

.dj-display .dj-display-warp {
  display: inline-block;
  text-align: left;
  max-width: 12rem;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dj-display .dj-display-warp .dj-display-title {
  font-size: 0.36rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.47rem;
  margin-bottom: 0.13rem;
}

.dj-display .dj-display-warp .dj-display-describe {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.21rem;
}

.dj-vedio {
  height: 8.1rem;
  background-color: cornflowerblue;
  background-image: url(~@/assets/images/display/banner_bg.jpg);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.dj-vedio::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.dj-vedio .dj-vedio-warp {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  color: #fff;
}

.dj-vedio .dj-vedio-warp .dj-vedio-title {
  display: inline-block;
  width: 6.86rem;
  height: 1.15rem;
  background: url(~@/assets/images/display/title.png);
  background-size: 100% 100%;
  margin: 2.41rem 0 0.35rem 0;
}

.dj-vedio .dj-vedio-warp .dj-vedio-describe {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.26rem;
  letter-spacing: 0.03rem;
  margin-bottom: 0.37rem;
}

.dj-vedio .dj-vedio-warp .dj-vedio-describe::before {
  margin-right: 0.1rem;
  display: inline-block;
  content: "";
  width: 0.42rem;
  height: 0.02rem;
  vertical-align: middle;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #fff 100%);
}

.dj-vedio .dj-vedio-warp .dj-vedio-describe::after {
  margin-left: 0.1rem;
  display: inline-block;
  content: "";
  width: 0.42rem;
  height: 0.02rem;
  vertical-align: middle;
  background: linear-gradient(90deg, #fff 0%, rgba(255,255,255,0) 100%);
}

.dj-vedio .dj-vedio-warp .dj-vedio-btn {
  display: inline-block;
  height: 0.6rem;
  width: 0.6rem;
  background: url(~@/assets/images/display/icon_play.png);
  background-size: 100% 100%;
  border-radius: 50%;
  margin-bottom: 1.62rem;
  cursor: pointer;
}

.dj-vedio .dj-vedio-warp .dj-vedio-line {
  display: block;
}

.dj-vedio .dj-vedio-warp .dj-vedio-line .s_line {
  display: inline-block;
  width: 0.02rem;
  height: 0.32rem;
  border-radius: 0.02rem;
  background: #fff;
  margin-bottom: 0.16rem;
  animation: a_banner_s_line 3s 0.3s linear both infinite;
  -webkit-animation: a_banner_s_line 3s 0.3s linear both infinite;
  -ms-animation: a_banner_s_line 3s 0.3s linear both infinite;
  -moz-animation: a_banner_s_line 3s 0.3s linear both infinite;
  -o-animation: a_banner_s_line 3s 0.3s linear both infinite;
}

.dj-vedio .dj-vedio-warp .dj-vedio-line .txt {
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.17rem;
}

@keyframes a_banner_s_line {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    opacity: 1;
  }

  20% {
    transform: scale(1, 0.01);
    -webkit-transform: scale(1, 0.01);
    -ms-transform: scale(1, 0.01);
    -moz-transform: scale(1, 0.01);
    -o-transform: scale(1, 0.01);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    opacity: 1;
  }

  30% {
    transform: scale(1, 0.01);
    -webkit-transform: scale(1, 0.01);
    -ms-transform: scale(1, 0.01);
    -moz-transform: scale(1, 0.01);
    -o-transform: scale(1, 0.01);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    opacity: 0;
  }

  31% {
    transform: scale(1, 0.01);
    -webkit-transform: scale(1, 0.01);
    -ms-transform: scale(1, 0.01);
    -moz-transform: scale(1, 0.01);
    -o-transform: scale(1, 0.01);
    transform-origin: 50% 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    opacity: 0;
  }

  50% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform-origin: 50% 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    opacity: 1;
  }
}

.dj-vedio-bg {
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1201px) {
  .dj-vedio {
    height: 11.4rem;
    background-image: none;
    background-color: #0064eb;
  }

  .dj-vedio:before {
    display: none;
  }

  .dj-vedio .dj-vedio-warp {
    margin-top: 1rem;
  }

  .dj-vedio .dj-vedio-warp .dj-vedio-describe {
    margin-bottom: 1.14rem;
  }

  .dj-vedio .dj-vedio-warp .dj-vedio-btn {
    width: 1rem;
    height: 1rem;
    margin-bottom: 2.4rem;
  }

  .dj-vedio .dj-vedio-warp .dj-vedio-describe {
    font-size: 0.24rem;
  }

  .dj-vedio .dj-vedio-warp .dj-vedio-describe:before,
  .dj-vedio .dj-vedio-warp .dj-vedio-describe:after {
    display: none;
  }

  .dj-vedio .dj-vedio-warp .dj-vedio-line .txt {
    font-size: 0.24rem;
  }
}