.dj-enterprise-news {
  text-align: center;
  padding-bottom: 0.81rem;
}

.dj-enterprise-news-content {
  width: 12rem;
  text-align: left;
  display: inline-block;
  margin-top: 0.6rem;
}

.dj-enterprise-news-content .loading {
  text-align: center;
  padding-top: 0.3rem;
}

@media (max-width: 1200px) {
  .dj-enterprise-news-content {
    width: 100%;
    padding: 0 0.6rem;
  }
}