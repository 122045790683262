.dj-industry-information {
  text-align: center;
  padding-bottom: 0.81rem;
}

.dj-industry-information-content {
  width: 12rem;
  text-align: left;
  display: inline-block;
  margin-top: 0.6rem;
}

.dj-industry-information-content .loading {
  text-align: center;
  padding-top: 0.3rem;
}