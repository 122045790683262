.video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 999999;
}

.video-box {
  width: 70%;
  max-width: 9.62rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-rate-control>video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  background: #000000;
}

.video-close {
  position: absolute;
  right: -0.12rem;
  top: 0;
  transform: translateX(100%);
}

@media (max-width: 1201px) {
  .video-box {
    width: 100%;
  }

  .video-close {
    display: none;
  }
}