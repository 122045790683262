.dj-header {
  height: 0.72rem;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.dj-header::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.dj-header .log {
  display: inline-block;
  vertical-align: middle;
  width: 1.2rem;
  height: 0.28rem;
  margin-right: 2.6rem;
  background-image: url("~@/assets/images/header/nav_logo_bg_white.png");
  background-size: 100% 100%;
}

.dj-header .lst {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.16rem;
  z-index: 100;
}

.dj-header .lst .itm {
  height: 0.72rem;
  line-height: 0.72rem;
  display: inline-block;
  padding: 0 0.25rem;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  text-align: center;
  color: #FFFFFF;
}

.dj-header .lst .itm .des {
  display: inline-block;
  position: relative;
}

.dj-header .lst .itm .des::after {
  position: absolute;
  left: 0;
  bottom: 0.2rem;
  content: "";
  height: 0.04rem;
  width: 100%;
  background: #0064EB;
  -webkit-transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform-origin: 50% 0%;
  transform: scale(0.01, 1);
  opacity: 0;
}

.dj-header .lst .itm .des:hover {
  color: #0064eb;
}

.dj-header .lst .itm .des:hover::after {
  transform: scale(1, 1);
  opacity: 1;
}

.dj-header .ent {
  vertical-align: middle;
  display: inline-block;
  margin-right: 0;
  margin-left: 0.6rem;
  background: #0064eb;
  border-radius: 0.05rem;
  padding: 0.09rem 0.32rem;
  color: #fff;
}

.dj-header>div {
  width: 100%;
  position: fixed;
  top: -0.72rem;
  transition: all 0.2s linear;
  transition-property: transform,top;
  z-index: 9999;
}

.dj-header>div.isFixed {
  top: 0;
}

.dj-header .header-menu-box {
  display: none;
}

.dj-header.is-white>div {
  background-color: #FFFFFF;
}

.dj-header.is-white .log {
  background-image: url("~@/assets/images/header/nav_logo_bg_blue.png");
}

.dj-header.is-white .lst .itm {
  color: #000000;
}

.hover .itm {
  color: #000;
}

.hover>div.isFixed::after {
  content: " ";
  height: 3.06rem;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0.72rem;
  left: 0;
  z-index: 100;
}

@media (max-width: 1201px) {
  .dj-header {
    height: 1.18rem;
  }

  .dj-header>div {
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-100%);
  }

  .dj-header>div.isFixed {
    transform: translateY(0);
  }

  .dj-header .lst,
  .dj-header .ent {
    display: none;
  }

  .dj-header .log {
    width: 1.78rem;
    height: 0.41rem;
    transform-origin: left;
    margin-left: 0.6rem;
  }

  .dj-header .header-menu-box {
    display: block;
    margin-right: 0.6rem;
  }

  .dj-header.menu-show .itm {
    color: #000;
  }
}