.block-header {
  position: relative;
  margin-bottom: 0.5rem;
}

.block-header-title {
  text-align: center;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.42rem;
  margin-bottom: 0;
}

.block-header-more {
  position: absolute;
  right: 0;
  margin-top: 0.07rem;
  text-align: right;
}

.block-header-more>a {
  font-size: 0.14rem;
  color: #333333;
  line-height: 0.19rem;
}

.block-header-more>a:hover {
  cursor: pointer;
  color: #3654EA;
}

.block.more-center .block-header-more {
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0;
}

.block.title-left .block-header-title {
  text-align: left;
}