.css-transition {
  animation: none !important;
  opacity: 0;
}

.anchor {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: top;
  position: absolute;
}