.item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.item-img {
  width: 100%;
}

.item-img-name {
  font-size: 0.26rem;
  color: #FFFFFF;
  line-height: 0.35rem;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.item-img>img {
  width: 100%;
  vertical-align: bottom;
}

.item-text {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(0,100,235,0.7);
  color: #FFFFFF;
}

.item-text>div {
  width: 100%;
  padding: 0 0.4rem;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.item-text-intro {
  font-size: 0.2rem;
  line-height: 0.3rem;
  margin-bottom: 0.22rem;
}

.item-text-more {
  font-size: 0.16rem;
  line-height: 0.21rem;
}

.item-text-more-img {
  margin-left: 0.08rem;
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-size: 100% 100%;
  vertical-align: bottom;
}

.item:hover .item-img-name {
  display: none;
}

.item:hover .item-text {
  display: block;
}