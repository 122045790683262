.item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0.4rem 0.1rem 0.4rem 0.3rem;
  background: rgba(255,255,255,0.16);
}

.item-title {
  font-size: 0.2rem;
  color: #FFFFFF;
  line-height: 0.26rem;
  margin-bottom: 0.2rem;
}

.item-content {
  font-size: 0.18rem;
  color: #FFFFFF;
  line-height: 0.24rem;
  opacity: 0.5;
}

.item:hover {
  position: relative;
  top: -0.1rem;
}