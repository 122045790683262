.djCarton>div:nth-of-type(2) {
  text-align: center;
}

.djCarton>div:nth-of-type(2) .con {
  padding: 0.5rem 0 0.8rem 0;
  margin-top: -0.24rem;
  margin-left: -0.23rem;
  display: inline-block;
  max-width: 12.24rem;
  width: 12.24rem;
  text-align: left;
}

.djCarton>div:nth-of-type(2) .con .itm {
  display: inline-block;
  width: 5.88rem;
  background: #ffffff;
  border: 0.01rem solid #EEEEEE;
  text-align: left;
  padding: 0.35rem 0 0.37rem 0.36rem;
  box-sizing: border-box;
  margin-top: 0.23rem;
  margin-left: 0.24rem;
}

.djCarton>div:nth-of-type(2) .con .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djCarton>div:nth-of-type(2) .con .itm .ico {
  width: 0.4rem;
  height: 0.4rem;
  margin-bottom: 0.31rem;
}

.djCarton>div:nth-of-type(2) .con .itm .titl {
  margin-bottom: 0.12rem;
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.31rem;
}

.djCarton>div:nth-of-type(2) .con .itm .des {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.21rem;
}

.djCarton>div:nth-of-type(2) .con>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djCarton/pain_iocn_dingdan.svg");
}

.djCarton>div:nth-of-type(2) .con>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djCarton/pain_iocn_songhuo.svg");
}

.djCarton>div:nth-of-type(2) .con>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djCarton/pain_iocn_suanzhang.svg");
}

.djCarton>div:nth-of-type(2) .con>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/product/djCarton/pain_iocn_jingyin.svg");
}

.djCarton>div:nth-of-type(3) {
  margin-top: -0.53rem;
}

.djCarton>div:nth-of-type(3) .con {
  padding: 0.68rem 0 0.8rem 0;
}

.djCarton>div:nth-of-type(3) .con .itm {
  text-align: center;
}

.djCarton>div:nth-of-type(3) .con .itm .lef {
  text-align: left;
  margin: 0 0.4rem;
  display: inline-block;
}

.djCarton>div:nth-of-type(3) .con .itm .lef .titl {
  font-size: 0.26rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.35rem;
  margin-bottom: 0.24rem;
}

.djCarton>div:nth-of-type(3) .con .itm .lef .des {
  max-width: 4.9rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.3rem;
  vertical-align: middle;
}

.djCarton>div:nth-of-type(3) .con .itm .rig {
  margin: 0.4rem;
  display: inline-block;
  height: 3.92rem;
  width: 6.3rem;
  vertical-align: middle;
  background-size: 100% 100%;
}

.djCarton>div:nth-of-type(3) .con .itm::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.djCarton>div:nth-of-type(3) .con>div:nth-of-type(1) .rig {
  background-image: url("~@/assets/images/product/djCarton/manage_bg_pt.png");
}

.djCarton>div:nth-of-type(3) .con>div:nth-of-type(2) .rig {
  background-image: url("~@/assets/images/product/djCarton/manage_bg_fw.png");
}

.djCarton>div:nth-of-type(3) .con>div:nth-of-type(3) .rig {
  background-image: url("~@/assets/images/product/djCarton/manage_bg_xz.png");
}

.djCarton>div:nth-of-type(4) {
  background: #0064eb;
}

.djCarton>div:nth-of-type(4) .lst {
  text-align: center;
  padding: 1rem 0 1.45rem 0;
}

.djCarton>div:nth-of-type(4) .lst .itm {
  display: inline-block;
  margin: 0 0.6rem;
}

.djCarton>div:nth-of-type(4) .lst .itm .ico {
  display: inline-block;
  height: 1.45rem;
  width: 1.45rem;
  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
}

.djCarton>div:nth-of-type(4) .lst .itm .inf {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.4rem;
  text-align: left;
}

.djCarton>div:nth-of-type(4) .lst .itm .inf .num {
  font-size: 0.26rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  line-height: 0.35rem;
  margin-bottom: 0.1rem;
}

.djCarton>div:nth-of-type(4) .lst .itm .inf .des {
  width: 1.45rem;
  margin-bottom: 0;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  line-height: 0.3rem;
  opacity: 0.7;
}

.djCarton>div:nth-of-type(4) .lst>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djCarton/programme_icon_research.svg");
}

.djCarton>div:nth-of-type(4) .lst>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djCarton/programme_icon_deep.svg");
}

.djCarton>div:nth-of-type(4) .lst>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djCarton/programme_icon_result.svg");
}

.djCarton>div:nth-of-type(5) .con {
  height: 3.6rem;
  background-color: #000000;
  position: relative;
}

.djCarton>div:nth-of-type(5) .con .warp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.djCarton>div:nth-of-type(5) .con .warp .titl {
  font-size: 0.28rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.37rem;
  margin-bottom: 0.24rem;
}

.djCarton>div:nth-of-type(5) .con .warp .btn-warp {
  text-align: center;
}

.djCarton>div:nth-of-type(5) .con .warp .btn-warp .btn {
  display: inline-block;
  width: 2.24rem;
  height: 0.7rem;
  background: #0064eb;
  border-radius: 0.04rem;
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.26rem;
  text-align: center;
  line-height: 0.7rem;
}