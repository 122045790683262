.slide-right-enter-active,
.slide-right-appear-active {
  position: relative;
  left: -2rem;
  opacity: 0;
}

.slide-right-enter-done,
.slide-right-appear-done {
  opacity: 1;
  position: relative;
  left: 0;
  transition-property: left, opacity;
  transition-duration: 1000ms;
}

.slide-right-exit-active {
  opacity: 1;
  position: relative;
  left: 0;
}

.slide-right-exit-done {
  position: relative;
  left: -2rem;
  opacity: 0;
  transition-property: left, opacity;
  transition-duration: 1000ms;
}