.tab {
  padding-top: 0.11rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.33rem;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  text-align: center;
}

.tab-item {
  min-width: 1.6rem;
  padding-bottom: 0.12rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tab-item.is-active {
  font-size: 0.26rem;
  font-weight: 500;
  color: #0064EB;
  line-height: 0.37rem;
  position: relative;
}

.tab-item.is-active:after {
  content: ' ';
  width: 1.04rem;
  height: 0.04rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #0064EB;
}