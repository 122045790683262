.tip {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
  padding: 0.32rem 0.3rem 0.36rem 0;
}

.tip-title {
  font-size: 0.2rem;
  color: #999999;
  line-height: 0.36rem;
  border-left: 0.1rem solid #0064EB;
  padding-left: 0.26rem;
}

.tip-text {
  font-size: 0.2rem;
  color: #333333;
  line-height: 0.26rem;
  margin-left: 0.36rem;
  margin-top: 0.21rem;
}

.tip:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
  position: relative;
  top: -0.1rem;
}