.checkbox {
  display: inline-block;
}

.checkbox .input {
  width: 0.14rem;
  height: 0.14rem;
  background: #ffffff;
  border-radius: 0.02rem;
  border: 0.01rem solid #dcdfe6;
  vertical-align: middle;
  margin-right: 0.06rem;
}

.checkbox .text {
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #303133;
  line-height: 0.22rem;
  vertical-align: middle;
}

.checkbox .error {
  color: #db0010;
}