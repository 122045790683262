.dj-details-center {
  text-align: center;
}

.dj-details-center-content {
  width: 12rem;
  text-align: center;
  display: inline-block;
  margin-top: 0.6rem;
  padding-bottom: 1.25rem;
}

.dj-details-center-content>* {
  text-align: left;
}

.dj-details-center-content .title {
  text-align: center;
  font-size: 0.3rem;
  color: #333333;
  line-height: 0.4rem;
  padding: 0 20%;
}

.dj-details-center-content .time {
  text-align: center;
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.21rem;
  border-bottom: 0.01rem solid #EEEEEE;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.28rem;
}

.dj-details-center-content .content {
  text-align: center;
  border: none !important;
}

.dj-details-center-content .content>div {
  display: inline-block;
  text-align: left;
  max-width: 10rem;
  width: 100%;
  font-size: 0.14rem;
  color: #666666;
  line-height: 0.24rem;
  padding: 0;
}

.dj-details-center-content .extension {
  text-align: center;
  margin-top: 0.51rem;
  margin-bottom: 0.8rem;
  font-size: 0.16rem;
  color: #666666;
  line-height: 0.21rem;
}

.dj-details-center-content .extension>img {
  width: 6.7rem;
  margin-top: 0.56rem;
  vertical-align: bottom;
}

.dj-details-center .app_more-btn-box {
  display: none;
}

@media (max-width: 1201px) {
  .dj-details-center {
    padding: 0 0.6rem;
  }

  .dj-details-center-content {
    width: 100%;
  }

  .dj-details-center-content .extension>img {
    width: 100%;
  }

  .dj-details-center .app_more-btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dj-details-center .app_more-btn-box .app_more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 0.73rem;
    background: #0064EB;
    font-size: 0.24rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.6rem;
  }

  .dj-details-center-content .title {
    padding: 0 10%;
    font-size: 0.36rem;
    line-height: 0.46rem;
  }

  .dj-details-center-content .time {
    font-size: 0.24rem;
    line-height: 0.3rem;
  }

  .dj-details-center-content .content>div {
    font-size: 0.26rem;
    line-height: 0.36rem;
  }

  .dj-details-center-content .extension {
    font-size: 0.24rem;
    line-height: 0.28rem;
  }
}