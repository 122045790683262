.refrash .img {
  display: inline-block;
  width: 1.2rem;
  height: 0.4rem;
  background-color: gray;
  margin-right: 0.28rem;
  vertical-align: middle;
}

.refrash .img .Code {
  height: 100%;
  width: 100%;
  font-size: 0.2rem;
  border: 0;
  background: #e5dcd4;
  text-align: center;
}

.refrash .ref {
  display: inline-block;
  cursor: pointer;
}

.refrash .ref .tex {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #0064eb;
  line-height: 0.22rem;
  margin-right: 0.08rem;
}

.refrash .ref .ico {
  display: inline-block;
  vertical-align: middle;
  width: 0.13rem;
  height: 0.13rem;
  background-image: url(~@/assets/images/contact/refrash.svg);
  background-size: 100% 100%;
}