.dj-case {
  text-align: center;
  padding-top: 0.6rem;
}

.dj-case-center {
  width: 12rem;
  text-align: left;
  display: inline-block;
}

.dj-case-center.story {
  width: 12.48rem;
  margin-bottom: 0.8rem;
}

.dj-case-center.story .story-left {
  position: relative;
}

.dj-case-center.story .story-left-img {
  width: 100%;
  vertical-align: bottom;
}

.dj-case-center.story .story-left-intro {
  position: absolute;
  width: 100%;
  top: 3.21rem;
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.3rem;
  padding: 0 0.69rem 0 0.5rem;
  box-sizing: border-box;
}

.dj-case-center.story .story-left-intro>pre {
  white-space: pre-line;
}

.dj-case-center.header {
  width: 12.48rem;
  margin-bottom: 0.8rem;
}

.dj-case-center.header .header-left {
  position: relative;
  padding-right: 0.11rem;
  padding-left: 0.48rem;
}

.dj-case-center.header .header-left-name {
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.42rem;
  margin-bottom: 0.15rem;
  margin-top: 0.25rem;
}

.dj-case-center.header .header-left-intro {
  font-size: 0.18rem;
  color: #999999;
  line-height: 0.35rem;
}

.dj-case-center.header .header-right-img {
  width: 100%;
  vertical-align: bottom;
}

.dj-case-whole {
  padding: 0.8rem 0;
  background: #F4FAFF;
}

.dj-case-whole.experts {
  background: #F5F5F5;
  padding-top: 0.6rem;
}

.dj-case-whole.cooperation {
  background-image: url("../../../../assets/images/case/data_bg.jpg");
  background-size: cover;
  background-position: center;
}

.dj-case-whole.cooperation .cooperation-title {
  text-align: center;
  font-size: 0.32rem;
  color: #FFFFFF;
  line-height: 0.42rem;
}

.dj-case-whole.cooperation .cooperation-sub-title {
  text-align: center;
  font-size: 0.2rem;
  color: rgba(255,255,255,0.7);
  line-height: 0.26rem;
  margin-top: 0.24rem;
  margin-bottom: 0.5rem;
}

.dj-case-whole.link {
  padding: 1.12rem 0;
  background-image: url("../../../../assets/images/case/contact_bottom_bg.jpg");
  background-size: cover;
}

.dj-case-whole.link>div {
  text-align: center;
}

.dj-case-whole.link .link-title {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 0.42rem;
  margin-bottom: 0.24rem;
}

.dj-case-whole.link .link-btn {
  width: 2.24rem;
  height: 0.7rem;
  background: #0064EB;
  border-radius: 0.04rem;
  border: none;
  font-size: 0.2rem;
  color: #FFFFFF;
  line-height: 0.26rem;
  cursor: pointer;
}