.detail-btn {
  width: 1.76rem;
  height: 0.51rem;
  line-height: 0.51rem;
  border: 0.01rem solid #0064EB;
  text-align: center;
  display: inline-block;
  color: #0064EB;
  cursor: pointer;
  font-size: 0.14rem;
}

.detail-btn-icon {
  margin-left: 0.08rem;
}

.detail-btn-icon>img {
  margin-bottom: -0.02rem;
}

.detail-btn-icon .normal {
  display: inline;
}

.detail-btn-icon .hover {
  display: none;
}

.detail-btn:hover {
  color: #FFFFFF;
  background: #0064EB;
}

.detail-btn:hover .detail-btn-icon .normal {
  display: none;
}

.detail-btn:hover .detail-btn-icon .hover {
  display: inline;
}

@media (max-width: 1201px) {
  .detail-btn {
    height: 0.51rem;
    font-size: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: initial;
  }

  .detail-btn-icon>img {
    width: 0.14rem;
    margin-bottom: 0;
  }
}