.djabout>div:nth-of-type(2) .con {
  text-align: center;
  padding: 0.4rem 0 0.1rem 0;
}

.djabout>div:nth-of-type(2) .con p {
  text-align: left;
  display: inline-block;
  max-width: 12rem;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.22rem;
  padding: 0.1rem 0;
}

.djabout>div:nth-of-type(3) {
  text-align: center;
}

.djabout>div:nth-of-type(3) .con {
  padding: 0.52rem 0 0.8rem 0;
  display: inline-block;
  max-width: 12rem;
  width: 12rem;
  text-align: left;
}

.djabout>div:nth-of-type(3) .con .top {
  padding-right: 0.16rem;
}

.djabout>div:nth-of-type(3) .con .top .itm {
  width: 100%;
  display: inline-block;
  height: 1.64rem;
  padding: 0.47rem 0 0 0.3rem;
}

.djabout>div:nth-of-type(3) .con .top .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
  background-color: #0064eb;
}

.djabout>div:nth-of-type(3) .con .top .itm:hover .info .titl {
  color: #fff;
}

.djabout>div:nth-of-type(3) .con .top .itm:hover .info .des {
  color: #fff;
}

.djabout>div:nth-of-type(3) .con .top .itm .ico {
  display: inline-block;
  width: 0.72rem;
  height: 0.72rem;
  vertical-align: middle;
  margin-right: 0.24rem;
}

.djabout>div:nth-of-type(3) .con .top .itm .info {
  display: inline-block;
  vertical-align: middle;
  max-width: 2.35rem;
}

.djabout>div:nth-of-type(3) .con .top .itm .info .titl {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.26rem;
  margin-top: 0.12rem;
  margin-bottom: 0.09rem;
}

.djabout>div:nth-of-type(3) .con .top .itm .info .des {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.21rem;
}

.djabout>div:nth-of-type(3) .con .bot {
  background: #F7FAFD;
  padding: 0.49rem 0 0.56rem 0;
}

.djabout>div:nth-of-type(3) .con .bot .titl {
  font-size: 0.22rem;
  color: #333333;
  line-height: 0.25rem;
  border-left: 0.06rem solid #0058CD;
  padding-left: 0.11rem;
  margin-left: 0.39rem;
  margin-bottom: 0.23rem;
}

.djabout>div:nth-of-type(3) .con .bot .wrap {
  position: relative;
  overflow: hidden;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm {
  display: inline-block;
  vertical-align: top;
  height: 0.9rem;
  width: 2.2rem;
  background-color: #0064EB;
  color: #FFFFFF;
  padding: 0 0.31rem;
  font-size: 0.16rem;
  position: relative;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm>div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm>div p {
  line-height: 0.19rem;
  margin-bottom: 0;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm+.itm {
  margin-left: 0.1rem;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm:first-child {
  text-align: right;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm:last-child {
  text-align: left;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm>div:nth-of-type(2) {
  display: none;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm:hover {
  background-color: #ffffff;
  color: #666666;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm:hover>div:nth-of-type(1) {
  display: none;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .itm:hover>div:nth-of-type(2) {
  display: inline-block;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .row {
  text-align: center;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .row+.row {
  margin-top: 0.1rem;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .row:nth-of-type(1) .itm {
  width: 100%;
  height: 1.38rem;
  text-align: center;
  line-height: normal;
  padding-top: 0.78rem;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .row:nth-of-type(3) .itm:nth-of-type(2) {
  width: 1.63rem;
  padding: 0;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .mask-left {
  background: #F7FAFD;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(128deg);
  top: 0;
  transform-origin: top left;
  left: 50%;
}

.djabout>div:nth-of-type(3) .con .bot .wrap .mask-right {
  background: #F7FAFD;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(128deg);
  top: 0;
  transform-origin: top left;
  left: 50%;
  transform-origin: top right;
  transform: translateX(-100%) rotate(-128deg);
}

.djabout>div:nth-of-type(4) .con {
  height: 4.53rem;
  text-align: center;
}

.djabout>div:nth-of-type(4) .con .top {
  padding: 1rem 0 0.45rem 0;
}

.djabout>div:nth-of-type(4) .con .top ul {
  margin-bottom: 0;
  text-align: center;
}

.djabout>div:nth-of-type(4) .con .top ul li {
  display: inline-block;
  position: relative;
}

.djabout>div:nth-of-type(4) .con .top ul li .tet {
  position: absolute;
  top: -0.34rem;
  right: 0;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.19rem;
}

.djabout>div:nth-of-type(4) .con .top ul li .cir {
  cursor: pointer;
  display: inline-block;
  width: 0.28rem;
  height: 0.28rem;
  background-color: #dbdbdb;
  border-radius: 50%;
  vertical-align: middle;
}

.djabout>div:nth-of-type(4) .con .top ul li::before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  height: 0.02rem;
  width: 0.76rem;
  background-color: #dbdbdb;
}

.djabout>div:nth-of-type(4) .con .top ul li:first-child::before {
  width: 0;
}

.djabout>div:nth-of-type(4) .con .top ul :global(li.active) .cir {
  background: rgba(0,100,235,0.13);
  text-align: center;
  position: relative;
}

.djabout>div:nth-of-type(4) .con .top ul :global(li.active) .cir::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 0.18rem;
  height: 0.18rem;
  background: #0064eb;
  border-radius: 50%;
}

.djabout>div:nth-of-type(4) .con .bot {
  max-width: 12rem;
  width: 100%;
  display: inline-block;
  padding: 0.45rem 0 0.82rem 0;
}

.djabout>div:nth-of-type(4) .con .bot>div {
  user-select: none;
  cursor: pointer;
  position: relative;
}

.djabout>div:nth-of-type(4) .con .bot .itm {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  padding: 0 0.16rem;
}

.djabout>div:nth-of-type(4) .con .bot .itm .titl {
  width: 2.58rem;
  border-bottom: 0.01rem solid #d2d2d2;
  padding-bottom: 0.12rem;
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.29rem;
  margin-bottom: 0.24rem;
}

.djabout>div:nth-of-type(4) .con .bot .itm .des {
  width: 2.58rem;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.21rem;
}

.djabout>div:nth-of-type(5) .con {
  text-align: center;
  font-size: 0;
  cursor: pointer;
  padding: 0.5rem 0 0.8rem 0;
}

.djabout>div:nth-of-type(5) .con .warp {
  overflow: hidden;
  display: inline-block;
  height: 3.32rem;
  width: 12.4rem;
  background: #fff;
}

.djabout>div:nth-of-type(5) .con .warp .itm {
  float: left;
  width: 3.84rem;
  height: 3.3rem;
  background: #ffffff;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0,0,0,0.1);
  margin: 0 0.12rem;
}

.djabout>div:nth-of-type(5) .con .warp .itm .img {
  width: 3.84rem;
  height: 2.16rem;
  background-color: salmon;
}

.djabout>div:nth-of-type(5) .con .warp .itm .con {
  text-align: left;
  padding: 0 0.24rem;
}

.djabout>div:nth-of-type(5) .con .warp .itm .con .dec {
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.18rem;
  margin: 0.16rem 0 0.09rem 0;
  overflow: hidden;
}

.djabout>div:nth-of-type(5) .con .warp .itm .con .tet {
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.24rem;
  overflow: hidden;
}

.djabout>div:nth-of-type(6) .con {
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
}

.djabout>div:nth-of-type(6) .con .area {
  width: 12rem;
  margin: 0 auto;
}

.djabout>div:nth-of-type(6) .con .map {
  flex: 0 1 11.77rem;
  overflow: hidden;
}

.djabout>div:nth-of-type(6) .con .map>div {
  width: calc(100% + 0.8rem);
  transform: translateX(-0.8rem);
  height: 100%;
}

.djabout>div:nth-of-type(6) .con .img {
  flex: 0 0 3.84rem;
  display: inline-block;
  position: relative;
}

.djabout>div:nth-of-type(6) .con .img .tip {
  position: absolute;
  left: 0.4rem;
  top: 0.97rem;
}

.djabout>div:nth-of-type(6) .con .img .tip .tip-icon {
  width: 0.39rem;
  height: 0.04rem;
  background: #0064EB;
}

.djabout>div:nth-of-type(6) .con .img .tip p {
  text-align: left;
  margin-top: 0.27rem;
  width: 3.1rem;
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.24rem;
}

.djabout>div:nth-of-type(6) .con .img img {
  width: 100%;
}

.djabout .history {
  background-image: url("../../assets/images/about/about_bg_course.jpg");
  background-size: auto 100%;
  background-position: center;
}

.djabout .history>div:first-child {
  height: 1.79rem;
  line-height: 1.79rem;
  padding: 0;
}