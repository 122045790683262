.djjoin {
  text-align: center;
}

.djjoin .con {
  display: inline-block;
}

.djjoin .con .text {
  display: block;
  font-size: 0.24rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.31rem;
  padding: 0.8rem 0 0.4rem 0;
}

.djjoin .con .warp {
  width: 12rem;
  background: #f5f5f5;
}

.djjoin .con .warp .form {
  display: inline-block;
  padding-top: 0.5rem;
  box-sizing: border-box;
}

.djjoin .con .warp .form .itm {
  padding: 0.15rem 0;
}

.djjoin .con .warp .form .itm .label {
  display: block;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #303133;
  line-height: 0.22rem;
  position: relative;
  left: -0.99rem;
  padding-bottom: 0.24rem;
  position: relative;
}

.djjoin .con .warp .form .itm .label::before {
  content: "*";
  color: #db0010;
  position: absolute;
  left: -0.12rem;
}

.djjoin .con .warp .form .itm:first-child {
  text-align: left;
}

.djjoin .con .warp .form .itm:last-child {
  text-align: left;
}

.djjoin .con .warp .form .itm .ref {
  display: inline-block;
  margin-left: 0.24rem;
  vertical-align: top;
}

.djjoin .con .warp .form .itm .btn {
  padding: 0.25rem 0 0.35rem 0;
}

.djjoin .con .warp .form .itm .btn .sub {
  width: 0.9rem;
  height: 0.38rem;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  border: 0;
  display: inline-block;
  text-align: center;
  line-height: 0.38rem;
  cursor: pointer;
  background: #0064eb;
  border-radius: 0.04rem;
  color: #ffffff;
}

.djjoin .con .warp .form .itm .btn .res {
  width: 0.9rem;
  height: 0.38rem;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  border: 0;
  display: inline-block;
  text-align: center;
  line-height: 0.38rem;
  cursor: pointer;
  color: #666666;
  background: #f5f5f5;
}