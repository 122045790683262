.question {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.question-que {
  position: relative;
  margin-bottom: 0.16rem;
}

.question-que-img {
  width: 0.19rem;
  height: 0.19rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.question-que-txt {
  margin-left: 0.35rem;
  font-size: 0.2rem;
  color: #0064EB;
  line-height: 0.26rem;
}

.question-text {
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.3rem;
}

.question-text>pre {
  white-space: pre-line;
}

.question+.question {
  margin-top: 0.24rem;
}