.honer {
  text-align: center;
}

.con {
  text-align: center;
  font-size: 0;
  cursor: pointer;
  padding: 0.5rem 0 0 0;
  margin-bottom: -0.8rem;
  display: inline-block;
  max-width: 12rem;
  width: 12rem;
  text-align: left;
}

.con .warp {
  display: inline-block;
  height: 4.12rem;
  width: 12.4rem;
  background: #fff;
  position: relative;
}

.con .warp .itm {
  width: 3.89rem;
  height: 3.32rem;
  background: #ffffff;
}

.con .warp .itm+.itm {
  margin-left: 0.25rem;
}

.con .warp .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.con .warp .itm .img {
  width: 100%;
  height: 2.42rem;
}

.con .warp .itm .img>img {
  width: 100%;
  height: 100%;
}

.con .warp .itm .con {
  width: 100%;
  text-align: left;
  padding: 0.16rem;
}

.con .warp .itm .con .dec {
  height: 0.3rem;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #0064EB;
  line-height: 0.30rem;
  overflow: hidden;
}

.con .warp .itm .con .tet {
  height: 0.3rem;
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.3rem;
}