.con {
  margin-top: 0.75rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

.con .lef {
  text-align: left;
  display: inline-block;
  max-width: 6rem;
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.27rem;
  vertical-align: middle;
  margin-right: 0.22rem;
}

.con .lef p {
  margin-bottom: 0;
}

.con .rig {
  display: inline-block;
  width: 5.78rem;
  height: 1.85rem;
  vertical-align: middle;
  position: relative;
  background-size: 100% 100%;
}

.con .rig::after {
  position: absolute;
  top: -0.19rem;
  right: 0;
  display: block;
  content: "";
  width: 2.89rem;
  height: 0.19rem;
  background-color: #0064eb;
}

.con::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}