.input {
  display: inline-block;
  position: relative;
}

.input input {
  width: 4.12rem;
  height: 0.48rem;
  background: #ffffff;
  border-radius: 0.04rem;
  border: 0.01rem solid #cccccc;
  padding: 0 0.13rem;
}

.input input:focus {
  border-color: #0064EB;
}

.input .label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -0.99rem;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #303133;
  line-height: 0.22rem;
}

.input .label::before {
  content: "*";
  color: #db0010;
  position: absolute;
  left: -0.12rem;
}

.input .err {
  position: absolute;
  bottom: -0.22rem;
  text-align: left;
  color: #db0010;
}

.input .boder_err {
  border: 0.01rem solid #db0010;
}