.dj-case {
  text-align: center;
  padding-top: 0.6rem;
}

.dj-case-center {
  width: 12rem;
  text-align: left;
  display: inline-block;
}

.dj-case-whole {
  margin-top: 0.84rem;
  padding: 0.8rem 0;
  background: #F4FAFF;
}