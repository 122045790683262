.dj-home-digCoo {
  background: url(~@/assets/images/home/home_bg.png);
  background-size: 100% 100%;
}

.dj-home-digCoo .dj-home-dig {
  padding-top: 1.4rem;
  text-align: center;
}

.dj-home-digCoo .dj-home-dig>div {
  text-align: left;
  max-width: 12rem;
  width: 100%;
  display: inline-block;
}

.dj-home-digCoo .dj-home-dig .dj-home-dig-titl {
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.42rem;
  background: -webkit-linear-gradient(119deg, #75d4ff 0%, #abebff 50%, #66dcc2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.24rem;
}

.dj-home-digCoo .dj-home-dig .dj-home-dig-dec {
  font-size: 0.16rem;
  font-weight: 400;
  opacity: 0.8;
  color: #ffffff;
  line-height: 0.22rem;
  margin-bottom: 1rem;
}

.dj-home-digCoo .dj-home-busi {
  text-align: right;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  max-width: 16.7rem;
  height: 8.08rem;
  background: #ffffff;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
  opacity: 0.98;
  position: relative;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab {
  background: #ffffff;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
  opacity: 0.9;
  position: relative;
  text-align: left;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1.1rem;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul li {
  text-align: center;
  float: left;
  min-width: 1.6rem;
  height: 1.1rem;
  font-size: 0.2rem;
  padding: 0 0.08rem;
  color: #666666;
  cursor: pointer;
  vertical-align: bottom;
  display: flex;
  align-items: flex-end;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul li i {
  display: inline-block;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul li i::after {
  display: block;
  content: "";
  width: 100%;
  height: 0.04rem;
  background: transparent;
  margin-top: 0.08rem;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul li:hover {
  font-size: 0.22rem;
  color: #0064eb;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul .active {
  font-size: 0.22rem;
  color: #0064eb;
}

.dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul .active i::after {
  background: #0064eb;
}

.dj-home-digCoo .dj-home-data {
  padding-bottom: 1.4rem;
  text-align: center;
}

.dj-home-digCoo .dj-home-data>div {
  max-width: 12rem;
  width: 100%;
  display: inline-block;
  text-align: left;
}

.dj-home-digCoo .dj-home-data .dj-home-data-ite {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.dj-home-digCoo .dj-home-data .dj-home-data-ite .dj-home-data-ite-ico {
  display: inline-block;
  width: 0.88rem;
  height: 0.88rem;
  background-color: #ffffff;
  margin-bottom: 0.29rem;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.dj-home-digCoo .dj-home-data .dj-home-data-ite .dj-home-data-ite-num {
  font-size: 0.4rem;
  color: #ffffff;
  line-height: 0.52rem;
  margin-bottom: 0.12rem;
}

.dj-home-digCoo .dj-home-data .dj-home-data-ite .dj-home-data-ite-dec {
  font-size: 0.16rem;
  color: #ffffff;
  line-height: 0.21rem;
  opacity: 0.8;
}

@media (max-width: 1201px) {
  .dj-home-digCoo {
    background-image: url(~@/assets/images/home/home_bg_app.jpg);
    background-size: cover;
    background-position: 79% top;
  }

  .dj-home-digCoo .dj-home-dig {
    padding: 0.9rem 0.6rem 0 0.6rem;
  }

  .dj-home-digCoo .dj-home-dig .dj-home-dig-dec {
    font-size: 0.24rem;
    line-height: 0.33rem;
    margin-bottom: 0.6rem;
  }

  .dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul {
    margin-left: 0.44rem;
    margin-bottom: 0;
  }

  .dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul>li {
    font-size: 0.24rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.33rem;
  }

  .dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul>li:hover,
  .dj-home-digCoo .dj-home-busi .dj-home-bus-warp .dj-home-busi-tab ul>li.active {
    font-size: 0.26rem;
    font-weight: 500;
    color: #0064EB;
    line-height: 0.37rem;
  }

  .dj-home-digCoo .dj-home-busi .dj-home-bus-warp {
    height: auto;
  }

  .dj-home-digCoo .dj-home-data .dj-home-data-ite .dj-home-data-ite-ico {
    background-size: 0.9rem;
  }

  .dj-home-digCoo .dj-home-data {
    margin-top: -0.38rem;
  }

  .dj-home-digCoo .dj-home-data .dj-home-data-ite {
    margin-top: 0.38rem;
  }

  .dj-home-digCoo .dj-home-data .dj-home-data-ite .dj-home-data-ite-dec {
    font-size: 0.24rem;
  }
}