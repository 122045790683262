.dj-home-dec {
  text-align: center;
  margin-top: .8rem;
  margin-bottom: 0.82rem;
  font-size: .22rem;
  font-family: MicrosoftYaqiHeiLight;
  color: #333333;
  line-height: 0.38rem;
}

@media (max-width: 1201px) {
  .dj-home-dec {
    font-size: 0.26rem;
  }
}