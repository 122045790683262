.dj-home-dynamic {
  margin-top: 1.2rem;
  margin-bottom: 1.21rem;
  text-align: center;
}

.dj-home-dynamic>div {
  width: 12rem;
  display: inline-block;
}

.dj-home-dynamic .dj-home-dynamic-titl {
  text-align: center;
  margin-bottom: .5rem;
}

.dj-home-dynamic .dj-home-dynamic-titl .dj-home-dynamic-titl-mai {
  vertical-align: middle;
  display: inline-block;
  font-size: .32rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: .42rem;
  margin-right: 48%;
}

.dj-home-dynamic .dj-home-dynamic-titl .dj-home-dynamic-titl-mor {
  display: inline-block;
  font-size: .16rem;
  font-family: MicrosoftYaHei;
  color: #0064EB;
  line-height: .21rem;
}

.dj-home-dynamic .dj-home-dynamic-lit {
  text-align: center;
  font-size: 0;
  cursor: pointer;
}

.dj-home-dynamic .dj-home-dynamic-lit .dj-home-dynamic-lit-itm {
  vertical-align: top;
  display: inline-block;
  width: 3.84rem;
  height: 3.3rem;
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0,0,0,0.1);
  margin-right: .24rem;
}

.dj-home-dynamic .dj-home-dynamic-lit .dj-home-dynamic-lit-itm .dj-home-dynamic-lit-itm-img {
  width: 3.84rem;
  height: 2.16rem;
  background-color: salmon;
}

.dj-home-dynamic .dj-home-dynamic-lit .dj-home-dynamic-lit-itm .dj-home-dynamic-lit-itm-con {
  text-align: left;
  padding: 0 .24rem;
}

.dj-home-dynamic .dj-home-dynamic-lit .dj-home-dynamic-lit-itm .dj-home-dynamic-lit-itm-con .dj-home-dynamic-lit-itm-dec {
  font-size: .14rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: .18rem;
  margin: .16rem 0 .09rem 0;
  overflow: hidden;
}

.dj-home-dynamic .dj-home-dynamic-lit .dj-home-dynamic-lit-itm .dj-home-dynamic-lit-itm-con .dj-home-dynamic-lit-itm-tet {
  font-size: .18rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: .24rem;
  overflow: hidden;
}

@media (max-width: 1201px) {
  .dj-home-dynamic {
    margin: 0.9rem 0.6rem;
    box-sizing: border-box;
  }

  .dj-home-dynamic .content {
    width: calc(100% + 1.2rem);
    position: relative;
    transform: translateX(-0.6rem);
    overflow: hidden;
  }

  .dj-home-dynamic .itm-box {
    width: 100%;
    padding: 0 0.6rem;
  }

  .dj-home-dynamic .app_more-btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dj-home-dynamic .app_more-btn-box .app_more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 0.73rem;
    background: #0064EB;
    font-size: 0.24rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.6rem;
    border-radius: 0.06rem;
  }

  .dj-home-dynamic>div {
    width: 100%;
  }
}