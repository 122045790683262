.item-group {
  width: 100%;
}

.row {
  width: 100%;
  white-space: nowrap;
}

.col {
  display: inline-block;
  vertical-align: top;
  white-space: initial;
}

.divider {
  display: inline-block;
}