.lst {
  position: absolute;
  top: 0.72rem;
  z-index: 200;
  height: 3.06rem;
  padding-right: 0.5rem;
  padding-top: 0.07rem;
}

.lst .itm {
  display: block;
  width: 0.84rem;
  margin: 0 -0.1rem;
  text-align: center;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.21rem;
  padding: 0.13rem 0;
}

.lst .itm:hover {
  color: #333333;
}