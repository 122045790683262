.item .titl {
  text-align: center;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.42rem;
  padding-top: 0.8rem;
}

.item .sub-title {
  margin-top: 0.16rem;
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.21rem;
}