.rate-img {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: bottom;
}

.rate-img-box>img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}