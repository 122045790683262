.tapbar {
  height: 0.72rem;
}

.con {
  text-align: center;
}

.con .lst {
  margin-bottom: 0;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  padding: 0 1.6rem;
  position: relative;
}

.con .lst::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: inline-block;
  content: "";
  width: 100%;
  height: 0.01rem;
  background-color: #eeeeee;
}

.con .lst .itm {
  line-height: 0.72rem;
  display: inline-block;
  padding: 0 0.41rem;
  font-size: 0.16rem;
  cursor: pointer;
  position: relative;
}

.con .lst .itm:hover {
  color: #0064eb;
}

.con .lst .itm.active {
  color: #0064eb;
}

.con .lst .itm.active::after {
  position: absolute;
  bottom: 0;
  display: block;
  content: "";
  width: 0.64rem;
  height: 0.02rem;
  background: #0064eb;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
}