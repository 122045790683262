.dj-videos-center {
  text-align: center;
}

.dj-videos-center-content {
  width: 12rem;
  text-align: left;
  display: inline-block;
  padding-bottom: 0.6rem;
}

.dj-videos-center-content-head {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 0.24rem;
  color: #333333;
  text-align: center;
  height: 0.33rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dj-videos-center-content-head span {
  color: #0066F4;
}

.dj-videos-center-content-head img {
  width: 0.03rem;
}

.dj-videos-center-content-head-icon {
  margin-right: 0.16rem;
  margin-left: 0.04rem;
}

.dj-videos-center-content-head-icon1 {
  margin-right: 0.04rem;
  margin-left: 0.16rem;
}

.dj-videos-center-content-tab {
  width: 12rem;
  height: 0.74rem;
  margin: 0.2rem auto 0.12rem;
  font-size: 0.2rem;
  color: #333333;
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem;
  align-items: center;
}

.dj-videos-center-content-tab div {
  width: 0.8rem;
  text-align: center;
}

.dj-videos-center-content-tab div:hover {
  cursor: pointer;
}

.dj-videos-center-content-tab .cur {
  color: #0066F4;
  font-weight: bold;
}

.dj-videos-center-content-tabH5 {
  height: 0.24rem;
  font-size: 0.16rem;
  color: #333333;
  display: flex;
  justify-content: space-between;
  margin: 0.2rem;
  align-items: center;
}

.dj-videos-center-content-tabH5 div {
  width: 0.8rem;
  text-align: center;
}

.dj-videos-center-content-tabH5 div:hover {
  cursor: pointer;
}

.dj-videos-center-content-tabH5 .cur {
  font-size: 0.18rem;
  color: #0066F4;
}

.dj-videos-center-content .all-btn {
  text-align: center;
  margin-top: 0.6rem;
}

.dj-videos-center-content .loading {
  text-align: center;
  padding-top: 0.3rem;
}

.dj-videos-center-content .no-data {
  margin: 0.8rem auto 0.2rem;
  text-align: center;
}

.dj-videos-center-content .no-data img {
  width: 0.45rem;
}

.dj-videos-center-content .no-data p {
  margin-top: 0.12rem;
  font-size: 0.2rem;
  color: #C0C4CC;
  line-height: 0.28rem;
}