html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
}

header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
  font-weight: normal;
}

html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

[hidefocus],
summary {
  outline: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-size: 100%;
}

sup,
sub {
  font-size: 83%;
}

pre,
code,
kbd,
samp {
  font-family: inherit;
}

q:before,
q:after {
  content: none;
}

textarea {
  overflow: auto;
  resize: none;
}

label,
summary {
  cursor: default;
}

a,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}

del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}

body,
textarea,
input,
button,
select,
keygen,
legend {
  font-size: 0.12rem;
  line-height: 1.14;
  color: #333;
  outline: 0;
}

body,
html {
  background: #fff;
  font-family: PingFangSC-Regular, PingFang SC, MicrosoftYaHei;
  -webkit-overflow-scrolling: touch;
}

a,
a:hover {
  color: #333;
}

::-webkit-scrollbar {
  width: 0 !important;
}

body {
  -ms-overflow-style: none;
  overflow-x: hidden;
}

body {
  overflow: -moz-scrollbars-none;
}

img {
  width: auto;
  height: auto;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
}

[class^=app_] {
  display: none;
}

@media (max-width: 1201px) {
  .noapp {
    display: none !important;
  }

  [class^=app_] {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.amap-logo {
  display: none;
}

.amap-copyright {
  opacity: 0;
}