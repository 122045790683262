.appBoar {
  padding: 0.6rem 0;
  background: #EFF3F8;
}

.appBoar_tab {
  padding-left: 0.6rem;
}

.appBoar_content {
  padding-top: 0.4rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.5rem;
}

.appBoar_content>div>div {
  display: flex;
  flex-wrap: nowrap;
}

.appBoar_item {
  width: 5.25rem;
  flex: 0 0 5.25rem;
  padding-right: 0.81rem;
}