.dj-home-boar {
  font-size: 0;
  text-align: center;
  margin-bottom: 1.19rem;
}

.dj-home-boar>div {
  text-align: left;
  display: inline-block;
  max-width: 12rem;
  width: 100%;
}

.dj-home-boar>div>div:nth-of-type(1)>div {
  background: url(~@/assets/images/home/home_business_bg_normal.jpg);
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

.dj-home-boar>div>div:nth-of-type(1)>div .dj-home-boar-ite-tit {
  font-weight: bold;
}

.dj-home-boar>div>div:nth-of-type(1)>div:hover {
  background: url(~@/assets/images/home/home_business_bg_hover.png);
  background-size: 100% 100%;
}

.dj-home-boar>div>div:nth-of-type(1)>div:hover .dj-home-boar-ite-tit {
  font-weight: normal;
}

.dj-home-boar>div>div:nth-of-type(1)>div:hover .dj-home-boar-ite-dec {
  display: none;
}

.dj-home-boar>div>div:nth-of-type(1)>div:hover .dj-home-boar-ite-dec-hover {
  display: block;
}

.dj-home-boar>div>div:nth-of-type(2)>div {
  background: url(~@/assets/images/home/home_culture_bg_normal.jpg);
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

.dj-home-boar>div>div:nth-of-type(2)>div .dj-home-boar-ite-tit {
  font-weight: bold;
}

.dj-home-boar>div>div:nth-of-type(2)>div:hover {
  background: url(~@/assets/images/home/home_culture_bg_hover.png);
  background-size: 100% 100%;
}

.dj-home-boar>div>div:nth-of-type(2)>div:hover .dj-home-boar-ite-tit {
  font-weight: normal;
}

.dj-home-boar>div>div:nth-of-type(2)>div:hover .dj-home-boar-ite-dec {
  display: none;
}

.dj-home-boar>div>div:nth-of-type(2)>div:hover .dj-home-boar-ite-dec-hover {
  display: block;
}

.dj-home-boar>div>div:nth-of-type(3)>div {
  background: url(~@/assets/images/home/home_region_bg_normal.jpg);
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

.dj-home-boar>div>div:nth-of-type(3)>div .dj-home-boar-ite-tit {
  font-weight: bold;
}

.dj-home-boar>div>div:nth-of-type(3)>div:hover {
  background: url(~@/assets/images/home/home_region_bg_hover.png);
  background-size: 100% 100%;
}

.dj-home-boar>div>div:nth-of-type(3)>div:hover .dj-home-boar-ite-tit {
  font-weight: normal;
}

.dj-home-boar>div>div:nth-of-type(3)>div:hover .dj-home-boar-ite-dec {
  display: none;
}

.dj-home-boar>div>div:nth-of-type(3)>div:hover .dj-home-boar-ite-dec-hover {
  display: block;
}

.dj-home-boar>div>div>div {
  display: inline-block;
  width: 4rem;
  height: 5.5rem;
  box-sizing: border-box;
  float: left;
}

.dj-home-boar .dj-home-boar-ite {
  display: inline-block;
  cursor: pointer;
  padding: 0 0.35rem;
  vertical-align: top;
}

.dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-tit {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #C0C4CC;
  line-height: 0.42rem;
  margin-top: 2rem;
  margin-bottom: 0.24rem;
  text-align: left;
}

.dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec {
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.4rem;
  text-align: left;
}

.dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec-hover {
  display: none;
  text-align: left;
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.24rem;
  opacity: 0.8;
  text-shadow: 0 0.16rem 0.2rem rgba(0,100,235,0.1);
}

.dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec-hover p {
  padding: 0.045rem 0;
}

@media (max-width: 1201px) {
  .dj-home-boar>div {
    padding: 0 0.6rem;
  }

  .dj-home-boar>div>div:nth-of-type(1)>div {
    background-position: center -2.1rem !important;
  }

  .dj-home-boar>div>div:nth-of-type(1)>div:hover {
    background: url(~@/assets/images/home/home_business_bg_normal.jpg);
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .dj-home-boar>div>div:nth-of-type(1)>div:hover .dj-home-boar-ite-tit {
    font-weight: bold;
  }

  .dj-home-boar>div>div:nth-of-type(2)>div {
    background-position: center -2.7rem !important;
  }

  .dj-home-boar>div>div:nth-of-type(2)>div:hover {
    background: url(~@/assets/images/home/home_culture_bg_normal.jpg);
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .dj-home-boar>div>div:nth-of-type(2)>div:hover .dj-home-boar-ite-tit {
    font-weight: bold;
  }

  .dj-home-boar>div>div:nth-of-type(3)>div {
    background-position: center -3.5rem !important;
  }

  .dj-home-boar>div>div:nth-of-type(3)>div:hover {
    background: url(~@/assets/images/home/home_region_bg_normal.jpg);
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .dj-home-boar>div>div:nth-of-type(3)>div:hover .dj-home-boar-ite-tit {
    font-weight: bold;
  }

  .dj-home-boar .dj-home-boar-ite.is-show .dj-home-boar-ite-btn img {
    transform: rotate(0);
  }

  .dj-home-boar>div>div>div:hover .dj-home-boar-ite-dec {
    display: flex !important;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333333;
  }

  .dj-home-boar>div>div>div:hover .dj-home-boar-ite-dec-hover {
    color: #666666;
    margin-top: 0.32rem;
  }

  .dj-home-boar .dj-home-boar-ite {
    width: 100%;
    height: 2.19rem;
    transition: 1s;
  }

  .dj-home-boar .dj-home-boar-ite.is-show {
    height: auto;
  }

  .dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-tit {
    margin-top: 0.6rem;
  }

  .dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec-hover {
    color: #666666;
    margin-bottom: 0.56rem;
    font-size: 0.24rem;
    line-height: 0.38rem;
  }

  .dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec-hover>p {
    margin-bottom: 0;
    padding: 0;
  }

  .dj-home-boar .dj-home-boar-ite-btn {
    font-size: 0.24rem;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
  }

  .dj-home-boar .dj-home-boar-ite-btn img {
    margin-left: 0.08rem;
    width: 0.16rem;
    height: 0.16rem;
    transform: rotate(180deg);
  }

  .dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec {
    display: flex;
    justify-content: space-between;
    font-size: 0.3rem;
  }

  .dj-home-boar>div>div>div:hover .dj-home-boar-ite-dec-hover {
    display: none !important;
  }

  .dj-home-boar>div>div .dj-home-boar-ite.is-show .dj-home-boar-ite-dec-hover {
    display: block !important;
  }

  .dj-home-boar .dj-home-boar-ite .dj-home-boar-ite-dec-hover {
    color: #666666;
    margin-top: 0.32rem;
  }
}