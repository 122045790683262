.item {
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.01rem solid #EEEEEE;
  text-align: left;
}

.item-content {
  padding: 0.16rem 0.24rem;
}

.item-img {
  width: 100%;
  position: relative;
}

.item-img .img {
  overflow: hidden;
}

.item-img .img img {
  transition: transform 0.5s;
}

.item-img-video-mask {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,100,235,0.5);
  text-align: center;
}

.item-img-video-mask>img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0.24rem;
  height: 0.24rem;
  margin-top: -0.12rem;
  margin-left: -0.12rem;
  vertical-align: bottom;
}

.item-time {
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.18rem;
  margin-bottom: 0.08rem;
}

.item-title {
  height: 0.48rem;
  font-size: 0.18rem;
  color: #333333;
  line-height: 0.24rem;
  margin-bottom: 0;
}

.item:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.item:hover .item-title {
  color: #0064EB;
}

.item:hover .item-img-video-mask {
  display: block;
}

.item:hover .img img {
  transform: scale(1.1);
}

@media (max-width: 1201px) {
  .item-time {
    font-size: 0.24rem;
    margin-bottom: 0.1rem;
    line-height: 0.31rem;
  }

  .item-title {
    height: auto;
    font-size: 0.26rem;
    line-height: 0.35rem;
  }

  .item-content {
    padding: 0.24rem;
  }
}