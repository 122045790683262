.dj-footer {
  height: 4.22rem;
  padding-top: 0.67rem;
  position: relative;
  background-image: url("../../../assets/images/footer/home_bottom_bg.png");
  background-size: auto 100%;
  background-position: center;
}

.dj-footer .dj-footer-top {
  text-align: center;
}

.dj-footer .dj-footer-top .dj-footer-top-item {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-right: 1.45rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item:last-child {
  margin-right: 0;
}

.dj-footer .dj-footer-top .dj-footer-top-item .dj-footer-top-item-title {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.21rem;
  margin-bottom: 0.3rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item p {
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 0.19rem;
  margin-bottom: 0.13rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item p:last-of-type {
  margin-bottom: 0;
}

.dj-footer .dj-footer-top .dj-footer-top-item .loca {
  display: inline-block;
  width: 1.28rem;
  height: 0.4rem;
  border-radius: 0.05rem;
  background-color: rgba(255,255,255,0.2);
  border: 0.01rem solid rgba(51,51,51,0.2);
  text-align: center;
  line-height: 0.4rem;
  margin-top: 0.3rem;
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.dj-footer .dj-footer-top .dj-footer-top-item .loca>img {
  width: 0.2rem;
  vertical-align: text-bottom;
  margin-right: 0.05rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div {
  display: inline-block;
  background: #ffffff;
}

.dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div>img {
  display: block;
  margin: 0.08rem 0.08rem 0.07rem 0.08rem;
  width: 1.12rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div>div {
  text-align: center;
  margin-bottom: 0.11rem;
  font-size: 0.13rem;
  color: #666666;
  line-height: 0.17rem;
}

.dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div+div {
  margin-left: 0.4rem;
}

.dj-footer .dj-footer-bot {
  text-align: center;
  position: absolute;
  bottom: 0.26rem;
  width: 100%;
}

.dj-footer .dj-footer-bot>span {
  font-size: 0.12rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.16rem;
  white-space: nowrap;
}

.dj-footer .dj-footer-bot>span>img {
  width: 0.2rem;
  vertical-align: bottom;
  margin-right: 0.04rem;
}

.dj-footer .dj-footer-bot>span+span {
  margin-left: 1.68rem;
}

@media (max-width: 1050px) {
  .dj-footer {
    height: auto;
    padding-top: 0.92rem;
    padding-bottom: 0.61rem;
    overflow: hidden;
    background-image: url("../../../assets/images/footer/home_bottom_bg_mobile.png");
    background-size: cover;
  }

  .dj-footer .dj-footer-bot {
    position: static;
    margin: 0.4rem 0 0 0.6rem;
  }

  .dj-footer .dj-footer-bot>span {
    display: block;
    margin: 0;
    text-align: left;
  }

  .dj-footer .dj-footer-bot>span+span {
    margin-top: 0.16rem;
  }

  .dj-footer .dj-footer-bot>span+span {
    margin-left: 0;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item {
    display: block;
    padding-left: 0.6rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item+.dj-footer-top-item {
    margin-top: 0.4rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .loca {
    max-width: 1.79rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border-radius: 0.07rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .QRCode {
    display: flex;
    margin-top: 0.41rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div+div {
    margin-left: 0.6rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .dj-footer-top-item-title {
    font-size: 0.26rem;
    line-height: 0.35rem;
    margin-bottom: 0.23rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item p {
    font-size: 0.24rem;
    line-height: 0.31rem;
    margin-bottom: 0.16rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .loca {
    font-size: 0.22rem;
    height: 0.56rem;
    margin-top: 0.41rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div>div {
    font-size: 0.24rem;
    line-height: 0.31rem;
  }

  .dj-footer .dj-footer-top .dj-footer-top-item .QRCode>div>img {
    width: 100%;
    max-width: 1.92rem;
    padding: 0.06rem;
    margin: 0.06rem;
  }

  .dj-footer .dj-footer-bot>span {
    font-size: 0.24rem;
    line-height: 0.31rem;
  }

  .dj-footer .dj-footer-bot>span>img {
    width: 0.28rem;
  }
}