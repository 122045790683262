.swiper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.swiper-list {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.swiper-list-item {
  display: inline-block;
  width: 100%;
  float: left;
}

.swiper-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0.28rem;
}

.swiper-dot {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  background: rgba(255,255,255,0.2);
  cursor: pointer;
}

.swiper-dot.active {
  background: rgba(255,255,255,0.8);
}

.swiper-dot+.swiper-dot {
  margin-left: 0.12rem;
}

.swiper-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 0.42rem;
  height: 0.53rem;
  margin-top: -0.265rem;
}

.swiper-btn.prev {
  left: 0;
}

.swiper-btn.next {
  right: 0;
}

.swiper-btn>img {
  width: 100%;
  height: 100%;
}

.swiper-btn .swiper-icon-normal {
  display: none;
}

.swiper-btn .swiper-icon-hover {
  display: block;
}

.swiper-btn:hover .swiper-icon-normal {
  display: none;
}

.swiper-btn:hover .swiper-icon-hover {
  display: block;
}