.slider {
  position: relative;
}

.slider .slider-wrap {
  left: 0;
  position: relative;
  white-space: nowrap;
}

.slider .slider-wrap>* {
  display: inline-block;
  vertical-align: top;
  white-space: initial;
}

.slider.is-transition .slider-wrap {
  transition: left 0.64s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.slider .pre {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  width: 0.65rem;
  height: 0.65rem;
  background: #ffffff;
  opacity: 0.9;
  z-index: 2000;
  box-shadow: 0 0.06rem 0.13rem 0 rgba(0,0,0,0.14);
  left: 0.24rem;
}

.slider .pre .ico {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0.19rem;
  width: 0.19rem;
  background-size: 100% 100%;
  background-image: url(~@/assets/images/about/lef.svg);
}

.slider .pre:hover .ico {
  background-image: url(~@/assets/images/about/lef_hover.svg);
}

.slider .nex {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  width: 0.65rem;
  height: 0.65rem;
  background: #ffffff;
  opacity: 0.9;
  z-index: 2000;
  box-shadow: 0 0.06rem 0.13rem 0 rgba(0,0,0,0.14);
  right: 0.42rem;
}

.slider .nex .ico {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0.19rem;
  width: 0.19rem;
  background-size: 100% 100%;
  background-image: url(~@/assets/images/about/rig.svg);
}

.slider .nex:hover .ico {
  background-image: url(~@/assets/images/about/rig_hover.svg);
}