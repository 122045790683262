.slide-up-enter-active,
.slide-up-appear-active {
  position: relative;
  top: 2rem;
  opacity: 0;
}

.slide-up-enter-done,
.slide-up-appear-done {
  position: relative;
  top: 0;
  opacity: 1;
  transition-property: top, opacity;
  transition-duration: 1000ms;
}

.slide-up-exit-active {
  position: relative;
  top: 0;
  opacity: 1;
}

.slide-up-exit-done {
  position: relative;
  top: 2rem;
  opacity: 0;
  transition-property: top, opacity;
  transition-duration: 1000ms;
}