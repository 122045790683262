.item {
  width: 100%;
  padding: 0.24rem 0;
  position: relative;
  border-bottom: 0.01rem solid #F5F5F5;
  cursor: pointer;
}

.item p {
  margin-bottom: 0;
}

.item>* {
  vertical-align: top;
}

.item .item-time {
  width: 1.61rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -0.375rem;
}

.item .item-time-date {
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.47rem;
}

.item .item-time-year {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.28rem;
}

.item .item-img {
  width: 1.8rem;
  height: 1.01rem;
  margin-top: 0.17rem;
  margin-left: 1.61rem;
  margin-right: 0.24rem;
  display: inline-block;
  background-size: cover;
  background-position: center center;
}

.item .item-content {
  width: 6rem;
  display: inline-block;
}

.item .item-content-title {
  font-size: 0.2rem;
  color: #333333;
  line-height: 0.26rem;
  margin-top: 0.16rem;
  margin-bottom: 0.16rem;
}

.item .item-content-sub-title {
  height: 0.77rem;
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.19rem;
}

.item .item-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
}

.item:hover .item-time>p {
  color: #0064EB;
}

.item:hover .item-content-title {
  color: #0064EB;
  font-weight: bold;
}

.item:hover .item-btn>div {
  color: #FFFFFF;
  background: #0064EB;
}

.item:hover .item-btn>div img:nth-of-type(1) {
  display: none;
}

.item:hover .item-btn>div img:nth-of-type(2) {
  display: inline;
}

@media (max-width: 1200px) {
  .item {
    display: flex;
    flex-direction: column;
    padding-top: 0.1rem;
  }

  .item .item-time {
    position: static;
    margin-top: 0;
  }

  .item .item-time {
    position: static;
  }

  .item .item-img {
    margin-top: 0.08rem;
    margin-left: 0;
    width: 100%;
    height: 4rem;
  }

  .item .item-btn {
    top: 0.25rem;
    transform: none;
  }

  .item .item-content {
    width: 100%;
  }

  .item .item-time-date {
    font-size: 0.36rem;
    line-height: 0.47rem;
  }

  .item .item-time-year {
    font-size: 0.26rem;
    line-height: 0.34rem;
  }

  .item .item-content-title {
    font-size: 0.3rem;
    line-height: 0.34rem;
  }

  .item .item-content-sub-title {
    font-size: 0.26rem;
    line-height: 0.34rem;
    height: auto;
  }
}