.appFirstScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appFirstScreen>p {
  margin-bottom: 0;
}

.appFirstScreen .appFirstScreen_title {
  margin-top: 1.8rem;
  margin-bottom: 0.3rem;
  font-size: 0.4rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.56rem;
}

.appFirstScreen .appFirstScreen_subTitle {
  font-size: 0.28rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.4rem;
  letter-spacing: 0.01rem;
  max-width: 4.66rem;
  width: 90%;
}

.appFirstScreen .appFirstScreen_animate {
  width: 5.6rem;
  height: 4.73rem;
}

.appFirstScreen .appFirstScreen_animate img {
  position: absolute;
  width: 5.98rem;
  left: 50%;
  transform: translateX(-50%);
}

.appFirstScreen .appFirstScreen_des {
  margin-top: 0.13rem;
  font-size: 0.26rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.46rem;
}

.appFirstScreen .appFirstScreen_playBtn {
  width: 2.6rem;
  height: 0.78rem;
  border-radius: 0.39rem;
  border: 0.01rem solid #FFFFFF;
  font-size: 0.24rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.33rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
  cursor: pointer;
}

.appFirstScreen .appFirstScreen_playBtnIcon {
  width: 0.42rem;
  height: 0.42rem;
  margin-right: 0.27rem;
}