.con {
  position: fixed;
  top: 0.96rem;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1000;
  transition: opacity 0.3s, transform 0.4s, top 1s;
}

.con .msg {
  width: 3.6rem;
  height: 0.52rem;
  background: #f1f9ec;
  border-radius: 0.04rem;
  border: 0.01rem solid #cff0bb;
  line-height: 0.52rem;
  text-align: left;
}

.con .msg .ico {
  display: inline-block;
  position: absolute;
  left: 0.16rem;
  top: 50%;
  transform: translateY(-50%);
}

.con .msg .ico .bz {
  border-radius: 50%;
  background-color: #40b317;
  width: 0.16rem;
  height: 0.16rem;
  background-image: url(~@/assets/images/contact/duhao.svg);
  background-size: 100% 100%;
}

.con .msg .tex {
  margin-left: 0.4rem;
  color: #40b317;
  display: inline-block;
}

.con .err {
  background: #fef0f0;
  border-color: #fde2e2;
}

.con .err .ico {
  background: red;
}

.con .err .ico .bz {
  background-image: url(~@/assets/images/contact/chahao.svg);
  background-size: 100% 100%;
}

.con .err .tex {
  color: #f56c6c;
}

.con .close {
  width: 0.13rem;
  color: #c0c4cc;
  font-size: 0.16rem;
  cursor: pointer;
  position: absolute;
  right: 0.16rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: initial;
}

.con .close>img {
  width: 100%;
  vertical-align: initial;
}