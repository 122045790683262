.djNet>div:nth-of-type(2) {
  text-align: center;
}

.djNet>div:nth-of-type(2) .con {
  padding: 0.5rem 0 0.8rem 0;
  margin-top: -0.24rem;
  margin-left: -0.23rem;
  display: inline-block;
  max-width: 12.24rem;
  width: 12.24rem;
  text-align: left;
}

.djNet>div:nth-of-type(2) .con .itm {
  display: inline-block;
  width: 5.88rem;
  background: #ffffff;
  border: 0.01rem solid #EEEEEE;
  text-align: left;
  padding: 0.35rem 0 0.37rem 0.36rem;
  box-sizing: border-box;
  margin-top: 0.23rem;
  margin-left: 0.24rem;
}

.djNet>div:nth-of-type(2) .con .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djNet>div:nth-of-type(2) .con .itm .ico {
  width: 0.4rem;
  height: 0.4rem;
  margin-bottom: 0.31rem;
}

.djNet>div:nth-of-type(2) .con .itm .titl {
  margin-bottom: 0.12rem;
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.31rem;
}

.djNet>div:nth-of-type(2) .con .itm .des {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.21rem;
}

.djNet>div:nth-of-type(2) .con>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djNet/pain_iocn_yewu.svg");
}

.djNet>div:nth-of-type(2) .con>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djNet/pain_iocn_yunying.svg");
}

.djNet>div:nth-of-type(2) .con>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djNet/pain_iocn_xiadan.svg");
}

.djNet>div:nth-of-type(2) .con>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/product/djNet/pain_iocn_zijin.svg");
}

.djNet>div:nth-of-type(3) {
  text-align: center;
}

.djNet>div:nth-of-type(3) .item-group {
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  max-width: 12rem;
  width: 100%;
  display: inline-block;
}

.djNet>div:nth-of-type(3) .item-group>div>div {
  transition: width 0.5s;
}

.djNet>div:nth-of-type(3) .item-group .itm:hover {
  background-color: #0064EB;
}

.djNet>div:nth-of-type(3) .item-group .itm:hover .des,
.djNet>div:nth-of-type(3) .item-group .itm:hover .titl {
  color: #ffffff;
}

.djNet>div:nth-of-type(3) .item-group .itm:hover .des {
  max-width: 4.37rem;
  opacity: 0.8;
}

.djNet>div:nth-of-type(3) .item-group .itm:hover .tip {
  background-color: #ffffff;
}

.djNet>div:nth-of-type(3) .item-group>div:nth-of-type(1)>div:nth-of-type(1) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_marketing.png");
}

.djNet>div:nth-of-type(3) .item-group>div:nth-of-type(1)>div:nth-of-type(2) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_manage.png");
}

.djNet>div:nth-of-type(3) .item-group>div:nth-of-type(2)>div:nth-of-type(1) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_paper.png");
}

.djNet>div:nth-of-type(3) .item-group>div:nth-of-type(2)>div:nth-of-type(2) .itm:hover {
  background-image: url("../../../assets/images/product/djCardboard/empower_technology.png");
}

.djNet>div:nth-of-type(3) .itm {
  width: 100%;
  position: relative;
  height: 4rem;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  background: #ffffff;
  border: 0.01rem solid #f5f5f5;
  text-align: left;
  padding: 0.6rem 0 0.6rem 0.6rem;
  background-repeat: no-repeat;
  background-size: 3.5rem auto;
  background-position: right bottom;
}

.djNet>div:nth-of-type(3) .itm .titl {
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.29rem;
  margin-bottom: 0.36rem;
}

.djNet>div:nth-of-type(3) .itm .des {
  max-width: 2.8rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.24rem;
}

.djNet>div:nth-of-type(3) .itm .titl {
  font-size: 0.22rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.29rem;
  margin-bottom: 0.36rem;
}

.djNet>div:nth-of-type(3) .itm .des {
  height: 2rem;
  overflow: hidden;
  max-width: 2.8rem;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 0.24rem;
}

.djNet>div:nth-of-type(3) .tip {
  width: 0.56rem;
  height: 0.08rem;
  background: #0064EB;
  position: absolute;
  bottom: 0.6rem;
  left: 0.6rem;
}

.djNet>div:nth-of-type(4) {
  background: #0064EB;
  border-radius: 0.01rem;
}

.djNet>div:nth-of-type(4) .lst {
  text-align: center;
  padding-bottom: 1.47rem;
  margin-top: 1rem;
}

.djNet>div:nth-of-type(4) .lst>div {
  display: inline-block;
  max-width: 12rem;
  width: 12rem;
  text-align: left;
}

.djNet>div:nth-of-type(4) .lst .itm {
  display: inline-block;
}

.djNet>div:nth-of-type(4) .lst .itm .ico {
  display: inline-block;
  height: 1.45rem;
  width: 1.45rem;
  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
}

.djNet>div:nth-of-type(4) .lst .itm .inf {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.4rem;
}

.djNet>div:nth-of-type(4) .lst .itm .inf .num {
  font-size: 0.26rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 0.35rem;
  margin-bottom: 0.1rem;
}

.djNet>div:nth-of-type(4) .lst .itm .inf .des {
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 0.3rem;
  opacity: 0.7;
}

.djNet>div:nth-of-type(5) {
  margin-bottom: 2.02rem;
}

.djNet>div:nth-of-type(5) .con-1 {
  height: 4.04rem;
  margin: 0.5rem 0 0.2rem 0;
  text-align: center;
}

.djNet>div:nth-of-type(5) .con-1 .lef {
  background-image: url("../../../assets/images/product/djNet/service_bg_yiwang.png");
  background-size: 100% 100%;
  display: inline-block;
  width: 3rem;
  height: 4.04rem;
  padding: 0.7rem 0.33rem 0 0.4rem;
  text-align: left;
  box-sizing: border-box;
}

.djNet>div:nth-of-type(5) .con-1 .lef .titl {
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.24rem;
  margin-bottom: 0.25rem;
}

.djNet>div:nth-of-type(5) .con-1 .lef .des {
  font-size: 0.14rem;
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.8);
  line-height: 0.24rem;
}

.djNet>div:nth-of-type(5) .con-1 .lst {
  display: inline-block;
  vertical-align: top;
  max-width: 9rem;
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm {
  width: 3rem;
  height: 1.35rem;
  text-align: center;
  float: left;
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm .ico {
  vertical-align: middle;
  display: inline-block;
  height: 0.6rem;
  width: 0.6rem;
  margin-right: 0.15rem;
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm .tet {
  vertical-align: middle;
  display: inline-block;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.24rem;
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm:hover {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.1);
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm:hover .tet {
  color: #0064eb;
}

.djNet>div:nth-of-type(5) .con-1 .lst .itm::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(1) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_yuanzhi.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(2) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_zhiban.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(3) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_jishi.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(4) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_fuliao.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(5) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_funeng.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(6) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_jinrong.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(7) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_gongxu.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(8) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_ziyuan.svg");
}

.djNet>div:nth-of-type(5) .con-1 .lst>div:nth-of-type(9) .ico {
  background-image: url("~@/assets/images/product/djNet/category_iocn_zixun.svg");
}

.djNet>div:nth-of-type(5) .con-2 {
  margin: 0 auto;
  width: 12rem;
  height: 0.8rem;
  background-image: url("../../../assets/images/product/djNet/service_bg_bottom.png");
  background-size: 100% 100%;
}

.djNet>div:nth-of-type(5) .con-2 .tex {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 0.21rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3.4rem;
}

.djNet>div:nth-of-type(5) .con-2 .btn {
  display: inline-block;
  width: 1.48rem;
  height: 0.42rem;
  background: #ffffff;
  vertical-align: middle;
  font-size: 0.18rem;
  font-family: MicrosoftYaHei;
  color: #0064eb;
  line-height: 0.24rem;
  margin-left: 2.08rem;
  text-align: center;
  line-height: 0.42rem;
  cursor: pointer;
}

.djNet>div:nth-of-type(5) .con-2::after {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.djNet>div:nth-of-type(6) {
  height: 6.53rem;
  background: url("~@/assets/images/product/djNet/bottom_bg_yiwang.png");
  background-size: auto 100%;
  background-position: center;
  position: relative;
  text-align: center;
}

.djNet>div:nth-of-type(6)>div {
  display: inline-block;
  max-width: 13.66rem;
  width: 13.66rem;
  text-align: left;
}

.djNet>div:nth-of-type(6) .bg {
  display: inline-block;
  max-width: 9.5rem;
  width: 100%;
  background-size: 100% 100%;
  position: relative;
  top: -1.07rem;
}

.djNet>div:nth-of-type(6) .bg img {
  width: 100%;
}

.djNet>div:nth-of-type(6) .code {
  float: right;
  display: inline-block;
  position: relative;
  top: 0.74rem;
  right: 0.47rem;
  width: 2.87rem;
  height: 3.53rem;
  background: #ffffff;
  border: 0.01rem solid #eeeeee;
  text-align: center;
}

.djNet>div:nth-of-type(6) .code .img {
  display: inline-block;
  width: 2.39rem;
  height: 2.39rem;
  background: url("~@/assets/images/product/djNet/bottom_bg_erweima.png");
  background-size: 100% 100%;
  margin: 0.24rem 0 0.3rem 0;
}

.djNet>div:nth-of-type(6) .code .tet {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.26rem;
}