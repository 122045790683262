.dj-news {
  text-align: center;
}

.dj-news-content {
  width: 12rem;
  text-align: left;
  display: inline-block;
  margin-top: 0.6rem;
}

.dj-news-content>div {
  margin-bottom: 0.8rem;
}

.dj-news-content .enterpriseNews-swiper {
  margin-bottom: 0.44rem;
}