.dj-home-busi-con_slider {
  text-align: left;
  position: relative;
}

.dj-home-busi-con_slider .lef {
  display: flex;
  flex-direction: column;
}

.dj-home-busi-con_slider .lef .itm {
  width: 100%;
  padding: 0 0.6rem;
}

.dj-home-busi-con_slider .lef .top {
  margin-top: 0.6rem;
  margin-bottom: 0.04rem;
  text-align: left;
}

.dj-home-busi-con_slider .lef .top .ico {
  display: inline-block;
  width: 1.02rem;
  height: 1.02rem;
  box-shadow: 0 0.05rem 0.16rem 0 rgba(22,119,255,0.1);
  border-radius: 0.16rem;
  vertical-align: middle;
  margin-right: 0.25rem;
  background-size: 100% 100%;
}

.dj-home-busi-con_slider .lef .top .des {
  display: inline-block;
  font-size: 0.3rem;
  font-family: MicrosoftYaHei;
  color: #19191b;
  line-height: 0.7rem;
  vertical-align: middle;
}

.dj-home-busi-con_slider .lef .fix {
  color: #666666;
  line-height: 0.28rem;
  font-size: 0.24rem;
}

.dj-home-busi-con_slider .lef .bot {
  text-align: left;
  position: relative;
  left: 0.16rem;
  margin-top: 0.7rem;
  width: 100%;
  margin-bottom: 0;
}

.dj-home-busi-con_slider .lef .bot .lin {
  position: absolute;
  left: -0.16rem;
  width: 0.02rem;
  height: 0.96rem;
  background: #0064eb;
}

.dj-home-busi-con_slider .lef .bot .lin::before {
  position: absolute;
  top: -0.03rem;
  left: -0.02rem;
  content: "";
  width: 0.06rem;
  height: 0.06rem;
  background: #0064eb;
  border-radius: 50%;
}

.dj-home-busi-con_slider .lef .bot .lin::after {
  position: absolute;
  bottom: -0.03rem;
  left: -0.02rem;
  content: "";
  width: 0.06rem;
  height: 0.06rem;
  background: #0064eb;
  border-radius: 50%;
}

.dj-home-busi-con_slider .lef .bot p {
  max-width: 3.75rem;
  margin-bottom: 0;
  font-size: 0.18rem;
  color: #333333;
  line-height: 0.28rem;
}

.dj-home-busi-con_slider .lef .bot .text1 {
  display: flex;
  font-size: 0.24rem;
  line-height: 0.3rem;
  max-width: 100%;
  height: auto;
  margin-top: 0.2rem;
  min-height: 0.96rem;
  align-items: flex-start;
}

.dj-home-busi-con_slider .lef .bot .text2 {
  height: 1.11rem;
}

.dj-home-busi-con_slider .app_img {
  margin-top: 0.45rem;
  display: block;
  width: 6rem;
}

.dj-home-busi-con_slider .app_more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 0.73rem;
  background: #0064EB;
  font-size: 0.24rem;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 0.91rem;
  align-self: center;
  margin-top: 0.6rem;
  border-radius: 0.06rem;
}